import React from 'react'
import database from '../firebase/firebase'
import { connect } from 'react-redux'
import { storage } from '../firebase/firebase'
import { popup } from '../actions/popup'

export class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item: '',
      data: [],
      revisions:[],
      users: [],
      about: [],
      additionalServices: [],
      aboutPhoto: '',
      itemAbout: '',
      currentPageUsers: 0,
      currentPageRevisions: 0
    }
    this.pageSize = 5;
  }

  handleNavigation(e, index) {
    e.persist()
    const pagesCountRevisions = Math.ceil(this.state.revisions.length / this.pageSize);
    const pagesCountUsers = Math.ceil(this.state.users.length / this.pageSize);
    if(this.state.item === 'users') {
      this.setState({
        currentPageUsers: index < 0 ? 0 : index >= pagesCountUsers ? (index - 1) : index
      })
    } else if(this.state.item === 'revisions') {
      this.setState({
        currentPageRevisions: index < 0 ? 0 : index >= pagesCountRevisions ? (index - 1) : index
      })
    }
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  onPopup(item){
    if (item.email) {
      this.props.popup({
        emailID: item.email.replace(/[@._]/g, ''),
        popupType: 'users',
        visible: true,
        data: item
      });
    } else {
      this.props.popup({
        popupType: 'users',
        visible: true,
        data: {
          firstName: '',
          lastName: '',
          password: '',
          email: '',
          photoProfile: '',
          status: ''
        }
      });
    }
  }

  closePopup () {
    this.setState({popup: false});
  }

  onDelete(e) {
    e.persist()
    const emailID = this.state.item.replace(/[@._]/g, '')
    if (this.state.item !== 's.roquette@luxus-consulting.com') {
      if (this.state.item !== 'pierre.barbe@luxus-private.com') {
        this.props.popup({
          propertyID: this.props.id,
          popupType: 'delete',
          field: 'users',
          visible: true,
          data: emailID
        })
      }
    }
  }

  upload (e) {
    const file = e.target.files[0]
    if (file) {
      const self = this
      const field = self.state.field
      const itemAbout = self.state.itemAbout
      const FR = new FileReader()
      this.setState({file: file})

      //Upload file to Firebase storage
      if (field === 'additionalServicesPDF') {
        const uploadTask = storage.ref(`files/${file.name}`).put(file)
        uploadTask.on('state_changed',snapshot => {},
          error => {
            console.log(error)
          },
          () => {
          storage.ref('files').child(file.name).getDownloadURL()
            .then( fileURL => {
              this.setState((prevState) => ({
                ...prevState,
                  additionalServices: {
                  ...prevState.additionalServices,
                    'fileURL': fileURL,
                    'fileName': file.name
                  }
                }
              ))
              database.ref(`additional-services`).update({
                fileURL: fileURL,
                fileName: file.name
              })
            }).then(fileURL => {
              this.componentDidMount()
            })
          }
        )
      }

      if (field === 'aboutPDF') {
        const uploadTask = storage.ref(`files/${file.name}`).put(file)
        uploadTask.on('state_changed',snapshot => {},
          error => {
            console.log(error)
          },
          () => {
          storage.ref('files').child(file.name).getDownloadURL()
            .then( fileURL => {
              this.setState((prevState) => ({
                ...prevState,
                  about: {
                    ...prevState.about,
                    [itemAbout]: {
                      ...prevState.about[itemAbout],
                      'fileURL': fileURL,
                      'fileName': file.name
                    }
                  }
                }
              ))
              database.ref(`about/description/${itemAbout}`).update({
                fileURL: fileURL,
                fileName: file.name
              })
            }).then(fileURL => {
              this.componentDidMount()
            })
          }
        )
      }

      FR.readAsDataURL(e.target.files[0]);
      FR.addEventListener('load', function(e) {
        if (field === 'aboutPhoto') {
          self.setState({
            aboutPhoto: e.target.result
          });
          database.ref(`about`).update({
            photo: e.target.result
          }).then(fileURL => {
            this.componentDidMount()
          })
        }
        if (field === 'additionalServicesPhoto') {
          self.setState((prevState) => ({
            ...prevState,
              additionalServices: {
                ...prevState.additionalServices,
                'photo': e.target.result
              }
            }
          ))
          database.ref(`additional-services`).update({
            photo: e.target.result
          }).then(fileURL => {
            this.componentDidMount()
          })
        }
      });
    }
  }

  handleSave() {
    database.ref('about/description').update(this.state.about)
  }

  handleSaveAdditionalServices(e) {
    database.ref('additional-services').update({
      text: this.state.additionalServices.text
    })
  }

  handleUserInput (e, item) {
    const entry = item[0]
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
        about: {
          ...prevState.about,
          [entry]: {
            ...prevState.about[entry],
            [name]: value
          }
        }
      }
    ))
  }

  handleUserInputAdditionalServices (e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
        additionalServices: {
          ...prevState.additionalServices,
          [name]: value
        }
      }
    ))
  }

  onSortChange(e) {
    e.persist()
    if (this.state.item === 'revisions') {
      const item = this.state.item
      const order = this.state.order
      const previousOrder = this.state.previousOrder
      const sorted = Object.values(this.state.revisions).sort(function(a, b) {
          return a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0);
      })
      const sortedDate = Object.values(this.state.revisions).sort(function(a, b) {
          return new Date(a.lastEdit) > new Date(b.lastEdit) ? 1 : (new Date(a.lastEdit) < new Date(b.lastEdit) ? -1 : 0);
      })

      if(order === 'lastEdit') {
        this.setState({
          revisions: previousOrder === order ? Object.values(this.state.revisions).sort(function(a, b) {
              return new Date(a.lastEdit) < new Date(b.lastEdit) ? 1 : (new Date(a.lastEdit) > new Date(b.lastEdit) ? -1 : 0)
          }) : sortedDate
        })
      } else {
        this.setState({
          revisions: previousOrder === order ? Object.values(this.state.revisions).sort(function(a, b) {
              return a[order] < b[order] ? 1 : (a[order] > b[order] ? -1 : 0);
          }) : sorted
        })
      }
      previousOrder === order ? this.setState({previousOrder: ''}) : this.setState({previousOrder: order})
    } else if (this.state.item === 'users') {
      const item = this.state.item
      const order = this.state.order
      const previousOrderUsers = this.state.previousOrderUsers
      const sorted = Object.values(this.state.users).sort(function(a, b) {
        return a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0);
      })

      this.setState({
        users: previousOrderUsers === order ? Object.values(this.state.users).sort(function(a, b) {
            return a[order] < b[order] ? 1 : (a[order] > b[order] ? -1 : 0);
        }) : sorted
      })
      previousOrderUsers === order ? this.setState({previousOrderUsers: ''}) : this.setState({previousOrderUsers: order})
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.popupData.updated === true) {
      this.componentDidMount()
    }
  }

  componentDidMount(){
    //Get about
    database.ref('about').once('value', (snapshot) => {
      const val = snapshot.val()
      if (snapshot.exists()) {
        this.setState({
          about: {
            'public': val.description.public,
            'private': val.description.private
          }
        })
        this.setState({
          aboutPhoto: snapshot.child('photo').val()
        })
      }
    })

    //Get additional services
    database.ref('additional-services').once('value', (snapshot) => {
      const val = snapshot.val()
      if (snapshot.exists()) {
        this.setState({
          additionalServices: val,
          additionalServicesPhoto: snapshot.child('photo').val()
        })
      }
    })

    //Get users
    database.ref('users').once('value', (snapshot) => {
      const val = snapshot.val()
      const newState = [];
      for (let item in val) {
        newState.push({
          firstName: val[item].firstName,
          lastName: val[item].lastName,
          status: val[item].status,
          email: val[item].email,
          password: val[item].password,
          photoProfile: val[item].photoProfile
        });
      }
      this.setState({
        users: newState
      });
    });

    database.ref('properties').once('value', (snapshot) => {
      const newState = [];
      var dateReformat;

      if (snapshot.exists()){
        snapshot.forEach(ss => {
          const contractualDocs = ss.child('contractualDocs').val();

          if (contractualDocs) {
            for (let item in contractualDocs) {
              newState.push({
                filename: contractualDocs[item].fileName ? contractualDocs[item].fileName : contractualDocs[item].name,
                property: `${ss.child('propertyAddress').val() + ' ' + ss.child('propertyPostCode').val() + ' ' + ss.child('propertyCity').val() + ', ' + ss.child('propertyCountry').val()}`,
                category: 'Contractual-doc',
                author: contractualDocs[item].author,
                lastEdit: contractualDocs[item].date
              });
            }
          }
          const documentsPhotos = ss.child('documentsPhotos').val();
          if (documentsPhotos) {
            for (let item in documentsPhotos) {
              newState.push({
                filename: documentsPhotos[item].fileName ? documentsPhotos[item].fileName : documentsPhotos[item].name,
                property: `${ss.child('propertyAddress').val() + ' ' + ss.child('propertyPostCode').val() + ' ' + ss.child('propertyCity').val() + ', ' + ss.child('propertyCountry').val()}`,
                category: documentsPhotos[item].category,
                author: documentsPhotos[item].author,
                lastEdit: documentsPhotos[item].date
              });
            }
          }
          const reports = ss.child('reports').val();
          if (reports) {
            for (let item in reports) {
              //Formating dates
              var date = new Date(reports[item].date);
              date.setDate(date.getDate());
              const mois = [
                "Jan", "Feb", "March",
                "Avr", "May", "June", "Jul",
                "August", "Sept", "Oct",
                "Nov", "Dec"
              ]

              newState.push({
                filename: `luxus_report_${mois[date.getMonth()-1]}_${date.getFullYear()}.pdf`,
                property: `${ss.child('propertyAddress').val() + ' ' + ss.child('propertyPostCode').val() + ' ' + ss.child('propertyCity').val() + ', ' + ss.child('propertyCountry').val()}`,
                category: 'Luxus Report',
                author: reports[item].author,
                lastEdit: reports[item].date
              });
            }
          }
          // //Sort newState by last edit
          // const sortedBylastEdit = newState.sort((a, b) => new Date(...b.lastEdit.split('/').reverse()) - new Date(...a.lastEdit.split('/').reverse()));

          this.setState({
            revisions: newState
          });
        });
      }
    })
  }

  render() {
    const { currentPageRevisions, currentPageUsers  } = this.state;
    var self = this
    const users = this.state.users.sort(function(a, b) {
      if (self.state.users.firstName && self.state.users.lastName) {
        const nameA = a.firstName.toLowerCase();
        const nameB = b.firstName.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }
    })

    return (
      <div>
        <div className="wrapper">
          <main className="settings">
            <section className="section-1">
              <div className="container">
                <div className="box-item no-hover padding">
                  <h2>Users</h2>
                  <button onClick={(event) => this.onPopup(event)} className="btn-third align-right">Add new</button>
                  <div className="overflow-x-mobile">
                    <table className="blanc users collaborators">
                      <thead>
                        <tr>
                          <th><span onClick={(e) => this.setState({order: 'firstName', item:'users'}, () => {this.onSortChange(e)})} className={this.state.order === 'firstName' ? 'order checked' : 'order'}>Name</span></th>
                          <th><span onClick={(e) => this.setState({order: 'email', item:'users'}, () => {this.onSortChange(e)})} className={this.state.order === 'email' ? 'order checked' : 'order'}>Email</span></th>
                          <th><span onClick={(e) => this.setState({order: 'status', item:'users'}, () => {this.onSortChange(e)})} className={this.state.order === 'status' ? 'order status' : 'order'}>Status</span></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.slice(currentPageUsers * this.pageSize, (currentPageUsers + 1) * this.pageSize).map((item) => {
                            return (
                              <tr key={item.firstName + item.lastName}>
                                <td><div className="image-profile" style={{backgroundImage: "url("+item.photoProfile+")" }}/><p>{item.firstName + ' ' + item.lastName}</p></td>
                                <td><span className="slate-gray">{item.email}</span></td>
                                <td><span>{item.status}</span></td>
                                <td>
                                  <ul className="buttons">
                                    <li onClick={(event) => this.onPopup(item)} className="edit"/>
                                    {item.email === 's.roquette@luxus-consulting.com' ? '' : item.email === 'pierre.barbe@luxus-private.com' ? '' : <li onClick={(event) => this.setState({'item': item.email}, () => this.onDelete(event))} className="delete"/>}
                                  </ul>
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className={`${"btn-prev cararra"} ${currentPageUsers > 0 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'users'}, () => {this.handleNavigation(e, currentPageUsers -1)})}
                    previous
                  />
                  <button
                    className={`${"btn-next cararra"} ${currentPageUsers < Math.ceil((this.state.users.length / this.pageSize) - 1) ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'users'}, () => {this.handleNavigation(e, currentPageUsers + 1)})}
                    next
                  />
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <div className="box-item no-hover padding">
                  <h2>Revisions history</h2>
                  <div className="overflow-x-mobile">
                    <table className="blanc revisions-history">
                      <thead>
                        <tr>
                          <th><span onClick={(e) => this.setState({order: 'filename', item:'revisions'}, () => {this.onSortChange(e)})} className={this.state.order === 'filename' ? 'order checked' : 'order'}>File name</span></th>
                          <th><span onClick={(e) => this.setState({order: 'property', item:'revisions'}, () => {this.onSortChange(e)})} className={this.state.order === 'property' ? 'order checked' : 'order'}>Property</span></th>
                          <th><span onClick={(e) => this.setState({order: 'category', item:'revisions'}, () => {this.onSortChange(e)})} className={this.state.order === 'category' ? 'order checked' : 'order'}>Category</span></th>
                          <th><span onClick={(e) => this.setState({order: 'author', item:'revisions'}, () => {this.onSortChange(e)})} className={this.state.order === 'author' ? 'order checked' : 'order'}>Author</span></th>
                          <th><span onClick={(e) => this.setState({order: 'lastEdit', item:'revisions'}, () => {this.onSortChange(e)})} className={this.state.order === 'lastEdit' ? 'order checked' : 'order'}>Last edit</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.revisions.slice(currentPageRevisions * this.pageSize, (currentPageRevisions + 1) * this.pageSize).map((item) => {
                            // //Formating dates
                            const date = new Date(item.lastEdit);
                            date.setDate(date.getDate());
                            const dateReformat = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();
                            return (
                              <tr key={`${item.lastEdit} + ${item.author} + ${item.property} + ${item.category}`}>
                                <td><span>{item.filename}</span></td>
                                <td><span className="slate-gray">{item.property}</span></td>
                                <td><span className={`fc-event ${item.category}`}>{item.category === 'Contractual-doc' ? 'Contractual doc' : item.category}</span></td>
                                <td><span>{item.author}</span></td>
                                <td><span>{dateReformat}</span></td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className={`${"btn-prev cararra"} ${currentPageRevisions > 0 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'revisions'}, () => {this.handleNavigation(e, currentPageRevisions - 1)})}
                    previous
                  />
                  <button
                    className={`${"btn-next cararra"} ${currentPageRevisions < Math.ceil((this.state.revisions.length / this.pageSize) - 1) ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'revisions'}, () => {this.handleNavigation(e, currentPageRevisions + 1)})}
                    next
                  />
                </div>
              </div>
            </section>

            <section className="section-3">
              <div className="container">
                <div className="box-item no-hover padding">
                  <div className="row-fluid">
                    <div className="large-5 columns">
                      <h2>About photo</h2>
                      <input type="file" ref={(ref) => this.handleUpload = ref} onChange={(e) => this.upload(e)} hidden/>
                      <button onClick={(e) => {this.setState({field: 'aboutPhoto'}); this.handleUpload.click()}} className="btn-fifth">Select file</button>
                      <div className="about-photo" style={{backgroundImage: "url("+this.state.aboutPhoto+")" }}/>
                    </div>
                    <div className="large-7 columns">
                      {Object.entries(this.state.about).map((item,i,key) => {
                          return (
                            <div>
                              <form>
                                <label>Title</label>
                                <input
                                  type="text"
                                  name="title"
                                  className="title"
                                  onChange={(event) => this.handleUserInput(event, item)}
                                  value={item[1].title}
                                />
                                <label>Text</label>
                                <textarea
                                  className="text"
                                  name="text"
                                  onChange={(event) => this.handleUserInput(event, item)}
                                  value={item[1].text}
                                />
                              </form>
                              <ul className="upload">
                                <li><button onClick={(e) => this.setState({field: 'aboutPDF', itemAbout: item[0]}, () => {this.handleUpload.click()})} className="btn-third">Upload PDF</button></li>
                                <li>{item[1].fileURL ? <p>{item[1].fileName}</p> : ''}</li>
                              </ul>
                            </div>
                          )
                        }
                      )}
                      <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-4">
              <div className="container">
                <div className="box-item no-hover padding">
                  <div className="row-fluid">
                    <div className="large-5 columns">
                      <h2>Additional Services</h2>
                      <input type="file" ref={(ref) => this.handleUpload = ref} onChange={(e) => this.upload(e)} hidden/>
                      <button onClick={(e) => {this.setState({field: 'additionalServicesPhoto'}); this.handleUpload.click()}} className="btn-fifth">Select file</button>
                      <div className="about-photo" style={{backgroundImage: "url("+this.state.additionalServices.photo+")" }}/>
                    </div>
                    <div className="large-7 columns">
                      <form>
                        <label>Text</label>
                        <textarea
                          className="text"
                          name="text"
                          onChange={(e) => {const item = 'additionalServices'; this.handleUserInputAdditionalServices(e,item)}}
                          value={this.state.additionalServices.text}
                        />
                      </form>
                      <ul className="upload">
                        <li><button onClick={(e) => this.setState({field: 'additionalServicesPDF'}, () => {this.handleUpload.click()})} className="btn-third">Upload PDF</button></li>
                        <li>{this.state.additionalServices.fileURL ? <p>{this.state.additionalServices.fileName}</p> : ''}</li>
                      </ul>
                      <button onClick={(e) => this.handleSaveAdditionalServices(e)} className="btn-sixth">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
})

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
