import React from 'react';
import database from '../firebase/firebase';
import { connect } from 'react-redux';
import { popup } from '../actions/popup';

export class Documents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: '',
      propertyID: this.props.id,
      property: {
        documentsPhotos: []
      },
      search: '',
      savedDocumentsPhotos: [],
      currentDocumentsPhotos: 0
    }
    this.pageSize = JSON.parse(localStorage.getItem('user')).status === 'Client' ? 10 : 5;
  }

  handleNavigation(e, index) {
    e.persist()
    const pagesCountDocumentsPhotos = Math.ceil(Object.keys(this.state.property.documentsPhotos).length / this.pageSize);
    this.setState({
      currentDocumentsPhotos: index < 0 ? 0 : index >= pagesCountDocumentsPhotos ? (index - 1) : index
    });
  }

  date(dateToFormat) {
    const date = new Date(dateToFormat);
    date.setDate(date.getDate());
    const mois = [
      "Jan", "Feb", "Mars",
      "Avr", "May", "June", "Jul",
      "August", "Sept", "Oct",
      "Nov", "Dec"
    ];
    const dateReformat = date.getFullYear() + ' ' + mois[date.getMonth()] + '. ' + date.getDate();
    return dateReformat
  }

  datePDFReport(dateToFormat) {
    const date = new Date(dateToFormat);
    date.setDate(date.getDate());
    const dateReformat = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
    return dateReformat
  }

  onPopup(data, key){
    if (key) {
      this.props.popup({
        propertyID: this.props.id,
        ID: key,
        popupType: 'documentsPhotos',
        visible: true,
        data: data
      });
    } else {
      this.props.popup({
        propertyID: this.props.id,
        popupType: 'documentsPhotos',
        visible: true,
        data: {
          author: this.state.user,
          category: 'Invoice',
          name: '',
          date: ''
        }
      });
    }
  }

  onDelete(e, id) {
    e.preventDefault()
    this.props.popup({
      propertyID: this.props.id,
      popupType: 'delete',
      field: 'documentsPhotos',
      visible: true,
      data: id
    })
  }


  earch (e) {
    e.preventDefault()
    const value = e.target.value
    this.setState({
      search: value
    })

    function filterByValue(array, string) {
      return array.filter(o =>
        Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
    }

    this.setState({
      property: {
        'documentsPhotos': filterByValue(this.state.savedDocumentsPhotos, value).length > 0 ? filterByValue(this.state.savedDocumentsPhotos, value) : this.state.savedDocumentsPhotos
      }
    })
  }

  componentWillReceiveProps(nextProps, popupData) {
    if (nextProps.popupData.updated === true) {
      this.componentDidMount()
    }
  }

  componentDidMount() {
    if (this.props.id) {
      localStorage.setItem('idProperty', this.props.id);
    }
    const ID = localStorage.getItem('idProperty');
    database.ref(`properties/${ID}`).once('value', (snapshot) => {
      const val = snapshot.val()
      const newState = [];
      for (let item in val.documentsPhotos) {
        newState.push({
          id: item,
          author: val.documentsPhotos[item].author,
          category: val.documentsPhotos[item].category,
          date: val.documentsPhotos[item].date,
          fileName: val.documentsPhotos[item].fileName,
          fileURL: val.documentsPhotos[item].fileURL,
          name: val.documentsPhotos[item].name
        });
      }

      this.setState({
        property: {
          documentsPhotos: newState
        },
        'savedDocumentsPhotos': newState
      })
    })

    //Get logged user
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
      user: user.firstName + ' ' + user.lastName,
      userStatus: user.status
      }
    ))

    if (this.props.location !== undefined) {
      if(this.props.location.pathname === '/documents') {
        this.setState({
          location: '/documents'
        });
      }
    }
  }

  onSortChange(e) {
    e.persist()
    const documentsPhotos = this.state.property.documentsPhotos;
    if (documentsPhotos) {
      const order = this.state.order
      const sorted = Object.values(documentsPhotos).sort(function(a, b) {
          return a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)
      })
      const sortedDate = Object.values(documentsPhotos).sort(function(a, b) {
          return new Date(a.date) > new Date(b.date) ? 1 : (new Date(a.date) < new Date(b.date) ? -1 : 0)
      })
      if(order === 'date') {
        this.setState({
          property: {
            documentsPhotos: this.state.previousOrder === order ? Object.values(documentsPhotos).sort(function(a, b) {
                return new Date(a.date) < new Date(b.date) ? 1 : (new Date(a.date) > new Date(b.date) ? -1 : 0)
            }) : sortedDate
          }
        });
      } else {
        this.setState({
          property: {
            documentsPhotos: this.state.previousOrder === order ? Object.values(documentsPhotos).sort(function(a, b) {
                return a[order] < b[order] ? 1 : (a[order] > b[order] ? -1 : 0)
            }) : sorted
          }
        });
      }
      this.state.previousOrder === order ? this.setState({previousOrder: ''}) : this.setState({previousOrder: order})
    }
  }

  render() {
    const documentsPhotos = this.state.property ? this.state.property.documentsPhotos : '';
    const { currentDocumentsPhotos  } = this.state;

    return (
      <div className={ this.state.location === '/documents' ? 'wrapper' : '' }>
        <section className="section-2">
          <div className="container">
            <div className="box-item no-hover padding">
              <h2>Documents and photos</h2>
              {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopup(event)} className="btn-third documentsPhotos align-right">Add new</button> : ''}
              {documentsPhotos.length > 0 ?
              <form>
                <input
                  type="text"
                  name="search"
                  className="search concrete"
                  placeholder="Search a document..."
                  onChange={(e) => this.
                    earch(e)}
                  value={this.state.search}
                />
              </form> : ''}
              <div className="overflow-x-mobile">
                <table className="blanc documents-photos">
                  <thead>
                    <tr>
                      <th><span onClick={(e) => this.setState({order: 'name'}, () => {this.onSortChange(e)})} className={this.state.order === 'name' ? 'order checked' : 'order'}>Name</span></th>
                      <th><span onClick={(e) => this.setState({order: 'date'}, () => {this.onSortChange(e)})} className={this.state.order === 'date' ? 'order checked' : 'order'}>Date</span></th>
                      <th><span onClick={(e) => this.setState({order: 'category'}, () => {this.onSortChange(e)})} className={this.state.order === 'category' ? 'order checked' : 'order'}>Category</span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { documentsPhotos ? Object.keys(documentsPhotos).slice(currentDocumentsPhotos * this.pageSize, (currentDocumentsPhotos + 1) * this.pageSize).map((key, item, i) => {
                        const dateToFormat = documentsPhotos[key].date;
                        const data = documentsPhotos[key];
                        const id = documentsPhotos[key].id
                        return (
                          <tr key={`${i} ${key}`}>
                            <td><span>{documentsPhotos[key].name}</span></td>
                            <td><span className="slate-gray">{this.date(dateToFormat)}</span></td>
                            <td><span className={`fc-event ${documentsPhotos[key].category}`}>{documentsPhotos[key].category}</span></td>
                            <td>
                              <ul className="buttons">
                                {documentsPhotos[key].fileURL ? <li onClick={e => window.open(documentsPhotos[key].fileURL)} className="view"/> : ''}
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onPopup(data, id)} className="edit"/> : ''}
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onDelete(event, id)} className="delete"/> : ''}
                              </ul>
                            </td>
                          </tr>
                        )
                      }
                    ) : ''}
                  </tbody>
                </table>
              </div>
              { documentsPhotos ?
                <div>
                  <button
                    className={`${"btn-prev cararra"} ${currentDocumentsPhotos > 0 ? ' checked' : ''}`}
                    onClick={(e) => this.handleNavigation(e, currentDocumentsPhotos - 1)}
                    previous
                  />
                  <button
                    className={`${"btn-next cararra"} ${currentDocumentsPhotos < Math.ceil(Object.keys(this.state.property.documentsPhotos).length / this.pageSize) - 1 ? ' checked' : ''}`}
                    onClick={(e) => this.handleNavigation(e, currentDocumentsPhotos + 1)}
                    next
                  />
              </div> : ''}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
