export function date(dateToFormat) {
  const date = new Date(dateToFormat);
  date.setDate(date.getDate())
  const mois = [
    "Jan", "Feb", "Mars",
    "Avr", "May", "June", "Jul",
    "August", "Sept", "Oct",
    "Nov", "Dec"
  ]
  const dateReformat = date.getFullYear() + ' ' + mois[date.getMonth()] + '. ' + date.getDate()
  return dateReformat
}

export function time(dateToFormat) {
  let d = new Date(dateToFormat)
  let hours = ('0' + (d.getHours()-1)).slice(-2)
  let minutes = ('0' + (d.getMinutes())).slice(-2)
  return `${hours}:${minutes}`
}
