import React from 'react'
import DropzoneComponent from 'react-dropzone-component'
import DatePicker from 'react-datepicker'
import DatePickerB from 'react-datepicker'
import { date, time } from '../functions/Date.js'
import "react-datepicker/dist/react-datepicker.css"
import Select from 'react-select'
import database from '../firebase/firebase'
import { storage } from '../firebase/firebase'
import { popup } from '../actions/popup'
import { connect } from 'react-redux'
import Report from './popups/Report'
import EditProperty from './AddProperty'
import uuidv1 from 'uuid/v1'

export class Popup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      file: '',
      field: '',
      hidden: true,
      propertyID: localStorage.getItem('idProperty'),
      user: {},
      popup: {
        visible: false,
        popupType: '',
        data: []
      },
      selectedOption: null,
      startDate: this.props.popupData.data.date ? new Date(this.props.popupData.data.date) : new Date(),
      endDate: new Date()
    }
    this.dropzone = null;
  }

  handleTimeStart = timeStart => {
    this.setState({
      'timeStart': timeStart
    });
  };

  handleTimeEnd = timeEnd => {
    this.setState({
      'timeEnd': timeEnd
    });
  };

  handleChange = date => {
    this.setState({
      startDate: date
    })
  };

  handleChangeB = date => {
    this.setState({
      endDate: date
    })
  };

  closePopup (e) {
    var self = this
    self.props.popup({popupType: '', visible: false, data: '', popupEditProperty: false});
    this.setState({
      popup: {
        visible: false
      }
    });
  }

  handlePost() {
    this.dropzone.processQueue();
  }

  handleUserInput (e) {
    if(this.state.userStatus === 'Admin') {
      e.preventDefault();
      const name = e.target.name;
      const value = e.target.value;
      this.setState((prevState) => ({
        ...prevState,
          popup: {
            ...prevState.popup,
            data: {
              ...prevState.popup.data,
              [name]: value
            }
          }
        }
      ))
    }
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  uploadImage (e) {
    if (e.target.files[0]) {
      const self = this;
      const emailID = this.props.data.email.replace(/[@._]/g, '');
      const FR = new FileReader();
      FR.readAsDataURL(e.target.files[0]);
      FR.addEventListener('load', function(e) {
        self.setState((prevState) => ({
          ...prevState,
            popup: {
              ...prevState.popup,
              data: {
                ...prevState.popup.data,
                photoProfile: e.target.result
              }
            }
          }
        ), () => {
          const userRef = database.ref('users/' +  emailID);
          userRef.update({'photoProfile': e.target.result})
        })
      });
    }
  };

  handleFileAdded(file) {
    this.setState(() => ({ 'file': file }));
  }

  removeFile() {
    this.setState(() => ({file: ''}));
  }

  deleteEntry(e){
    e.preventDefault()
    if(this.props.popupData.field === 'users') {
      const userRef = database.ref('users/' + this.props.data)
      userRef.remove()
    }
    if (this.props.popupData.field === 'property') {
      const userRef = database.ref('properties/' + this.props.data);
      userRef.remove()
      window.location.href = '/dashboard'
    } else {
      const userRef = database.ref('properties/' + this.state.propertyID + '/' +`${this.props.popupData.field}` + '/' + this.props.data);
      userRef.remove()
    }
    this.props.popup({
      visible: false,
      updated: true
    })
    this.setState({
      popup: {
        visible: false
      }
    })
  }

  deleteEvent(e) {
    e.preventDefault()
    const propertyID = this.state.propertyID;
    const popupType = this.state.popup.popupType;
    const ID = this.props.data.event._def.publicId;
    const agendaRef = database.ref(`properties/${propertyID}/${popupType}/${ID}`);
    agendaRef.remove()
    this.props.popup({
      visible: false,
      deleted: true
    });
    this.setState({
      popup: {
        visible: false
      }
    })
  }

  handleSave(e) {
    e.preventDefault();
    const file = this.state.file;
    const fileName = file.name

    //Upload file to Firebase storage
    if (file) {
      const uploadTask = storage.ref(`files/${file.name}`).put(file);
      uploadTask.on('state_changed',snapshot => {},
        error => {
          console.log(error)
        },
        () => {
        storage.ref('files').child(file.name).getDownloadURL()
          .then( fileURL => {
            this.sendDatasDB(e,fileURL, fileName)
          })
        }
      )
    } else {
      this.sendDatasDB(e)
    }
  }

  sendDatasDB(e, fileURL, fileName){
    e.preventDefault()
    const propertyID = this.state.propertyID;
    const popupType = this.state.popup.popupType;

    if(this.props.popupType === 'users') {
      if (this.props.popupData.emailID) {
        const userRef = database.ref('users/' + this.props.popupData.emailID);
        userRef.update({
          ...this.state.popup.data,
          status: this.state.popup.data.status ? this.state.popup.data.status : 'Admin',
          photoProfile: fileURL ? fileURL : this.state.popup.data.photoProfile
        })
      } else {
        if(this.state.popup.data.email !== '') {
          const newEmailID = this.state.popup.data.email.replace(/[@._]/g, '');
          const userRef = database.ref('users/' + newEmailID);
          userRef.update({
            ...this.state.popup.data,
            status: this.state.popup.data.status ? this.state.popup.data.status : 'Admin',
            photoProfile: fileURL ? fileURL : this.state.popup.data.photoProfile
          })
        }
      }
    } else {
      database.ref(`properties/${propertyID}`).once('value', (snapshot) => {
      }).then(value => {
        if(this.props.popupType === 'agenda') {
          const newID = uuidv1();
          const ID = this.props.data.event ? this.props.data.event._def.publicId : '';

          const dateStart = new Date(this.state.startDate);
          const dateEnd = new Date(this.state.endDate);
          dateStart.setDate(dateStart.getDate());
          dateEnd.setDate(dateEnd.getDate());
          const dateStartReformat = dateStart.getFullYear() + '/' + (dateStart.getMonth()+1) + '/' + dateStart.getDate();
          const dateEndReformat = dateEnd.getFullYear() + '/' + (dateEnd.getMonth()+1) + '/' + dateEnd.getDate();
          const newStartDate = new Date(`${dateStartReformat} ${this.state.timeStart.value}`)
          const newEndDate = new Date(`${dateEndReformat} ${this.state.timeEnd.value}`)
          newStartDate.setHours( newStartDate.getHours() - 1 );
          newEndDate.setHours( newEndDate.getHours() - 1 );

          const oldTimeStart = ('0' + this.props.data.event._instance.range.start.getHours()).slice(-2) + ':' + ('0' + this.props.data.event._instance.range.start.getMinutes()).slice(-2) + ':' + ('0' + this.props.data.event._instance.range.start.getSeconds()).slice(-2)
          const oldTimeEnd = ('0' + this.props.data.event._instance.range.end.getHours()).slice(-2) + ':' + ('0' + this.props.data.event._instance.range.end.getMinutes()).slice(-2) + ':' + ('0' + this.props.data.event._instance.range.end.getSeconds()).slice(-2);

          // console.log(this.state.timeEnd.value)
          if ((this.state.timeStart.value !== this.state.oldTimeStart) && (this.state.oldStartDate === this.state.startDate)) {
            newStartDate.setHours( newStartDate.getHours()+2);
          }
          if ((this.state.timeEnd.value !== this.state.oldTimeEnd) || (this.state.timeEnd.value !== this.state.oldtimeEnd) && (this.state.oldEndDate === this.state.endDate)) {
            newEndDate.setHours( newEndDate.getHours()+2);
          }

          database.ref(`properties/${propertyID}/${popupType}/`).update({
            [ID.length < 7 ? newID : ID]: {
              id: `${ID.length > 7 ? ID : newID}`,
              title: this.props.data.event ? this.props.data.event._def.title : this.props.data.title,
              start: new Date(newStartDate),
              end: new Date(newEndDate),
              backgroundColor: this.props.data.event ? this.props.data.event._def.ui.backgroundColor : this.props.data.backgroundColor
            }
          }).then((newStartDate,newEndDate) => {
              this.props.popup({updated: true})
            }
          )
        } else {
          if(this.props.popupData.ID) {
            if(this.props.popupType === 'sensors') {
              const startDate = new Date(this.state.startDate)
              const dateStartReformat = startDate.getFullYear() + '/' + (startDate.getMonth()+1) + '/' + startDate.getDate()
              const startDateAtCurrentTime = new Date(`${dateStartReformat} ${this.state.timeStart.value}`)
              // (this.props.data.name !== this.state.popup.data.name || this.props.data.details !== this.state.popup.data.details || this.props.data.status !== this.state.popup.data.status) ? newDate :

              const date = new Date(this.state.startDate)
              date.setHours(date.getHours())
              const time = ('' + date.getHours()).slice(-2) + ':' + ('' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)

              if (time === this.state.timeStart.value) {
                startDateAtCurrentTime.setHours(startDateAtCurrentTime.getHours())
              } else {
                startDateAtCurrentTime.setHours( startDateAtCurrentTime.getHours()+1)
              }

              database.ref(`properties/${propertyID}/${popupType}/`).update({
                [this.props.popupData.ID]: {
                  ...this.state.popup.data,
                  date: startDateAtCurrentTime
                }
              }).then(
                this.props.popup({
                  updated: true
                })
              )
            } else {
              database.ref(`properties/${propertyID}/${popupType}/`).update({
                [this.props.popupData.ID]: {
                  ...this.state.popup.data,
                  date: this.state.startDate ? this.state.startDate : this.state.popup.data.date,
                  fileURL: this.state.popup.data.fileURL ? this.state.popup.data.fileURL : fileURL ? fileURL : '',
                  fileName: this.state.popup.data.fileName ? this.state.popup.data.fileName : fileName ? fileName : ''
                }
              }).then(
                this.props.popup({
                  updated: true
                })
              )
            }
          } else {
            const newID = uuidv1()
            if(this.props.popupType === 'sensors') {

              const startDate = new Date(this.state.startDate)
              const dateStartReformat = startDate.getFullYear() + '/' + (startDate.getMonth()+1) + '/' + startDate.getDate()
              const startDateAtCurrentTime = new Date(`${dateStartReformat} ${this.state.timeStart.value}`)
              startDateAtCurrentTime.setHours(startDateAtCurrentTime.getHours()+1)

              database.ref(`properties/${propertyID}/${popupType}/`).update({
                [newID]: {
                  ...this.state.popup.data,
                  id: newID,
                  date: startDateAtCurrentTime,
                  status: 'On-site check'
                }
              }).then(
                this.props.popup({
                  updated: true
                })
              )
            } else {
              database.ref(`properties/${propertyID}/${popupType}/`).update({
                [newID]: {
                  ...this.state.popup.data,
                  date: this.state.popup.data.date !== this.state.startDate ? this.state.startDate : this.state.popup.data.date,
                  fileURL: this.state.popup.data.fileURL ? this.state.popup.data.fileURL : fileURL ? fileURL : '',
                  fileName: this.state.popup.data.fileName ? this.state.popup.data.fileName : fileName ? fileName : ''
                }
              }).then(
                this.props.popup({
                  updated: true
                })
              )
            }
          }
        }
      })
    }
    this.props.popup({
      visible: false,
      updated: true
    });
    this.setState({
      popup: {
        visible: false
      }
    })
  }

  componentWillMount() {
    //Get logged user
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
        user: user.firstName + ' ' + user.lastName,
        userStatus: user.status
      }
    ))
  }

  componentDidMount() {
    this.setState({
      popup: {
        visible: true,
        popupType: this.props.popupType,
        data: this.props.data
      }
    })
    if (this.props.popupType === 'sensors') {
      const newDate = new Date()
      newDate.setHours(newDate.getHours())
      const currentTime = `${newDate.getHours()}:${newDate.getMinutes()}:00`
      var hours = newDate.getHours()
      var minutes = newDate.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12
      minutes = minutes < 10 ? '0'+minutes : minutes
      var currentTimeStart = hours + ':' + minutes + ampm

      const date = new Date(this.props.data.date)
      var hours = date.getHours()-1
      var minutes = date.getMinutes()
      var ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12
      minutes = minutes < 10 ? '0'+minutes : minutes
      var timeStart = hours + ':' + minutes + ampm

      const time = `${date.getHours()}:${date.getMinutes()}:00`

      this.setState({
        oldTimeStart: `${date.getHours()-1}:${date.getMinutes()}:00`,
        timeStart: {
          label: this.props.data.date ? timeStart : currentTimeStart,
          value: this.props.data.date ? time : currentTime
        },
        popup: {
          visible: true,
          popupType: this.props.popupType,
          data: {
            ...this.props.data,
            date: this.props.data.date ? this.props.data.date : newDate
          }
        }
      })
    }
    if(this.props.popupType === 'agenda') {
      //Convert calendar date to AM/PM
      const startdate = new Date(this.props.data.event ? this.props.data.event._instance.range.start : new Date('2020-01-01T10:00'))
      var hours = startdate.getHours()-2;
      var minutes = startdate.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var timeStart = hours + ':' + minutes + ampm;

      const enddate = new Date(this.props.data.event ? this.props.data.event._instance.range.end : new Date('2020-01-01T11:00'))
      var hours = enddate.getHours()-2;
      var minutes = enddate.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var timeEnd = hours + ':' + minutes + ampm;

      this.setState({
        startDate: this.props.data.event ? this.props.data.event._instance.range.start : new Date(),
        endDate: this.props.data.event ? this.props.data.event._instance.range.end : new Date(),
        oldStartDate: this.props.data.event ? this.props.data.event._instance.range.start : new Date(),
        oldEndDate: this.props.data.event ? this.props.data.event._instance.range.end : new Date(),
        oldTimeStart: `${this.props.data.event ? this.props.data.event._instance.range.start.getHours() : '08'}:${this.props.data.event ? this.props.data.event._instance.range.start.getMinutes() : '00'}:00`,
        oldTimeEnd: `${this.props.data.event ? this.props.data.event._instance.range.end.getHours() : '08'}:${this.props.data.event ? this.props.data.event._instance.range.end.getMinutes() : '00'}:00`,
        timeStart: {
          label: timeStart,
          value: `${this.props.data.event ? this.props.data.event._instance.range.start.getHours() : '08'}:${this.props.data.event ? this.props.data.event._instance.range.start.getMinutes() : '00'}:00`
        },
        timeEnd: {
          label: timeEnd,
          value: `${this.props.data.event ? this.props.data.event._instance.range.end.getHours() : '08'}:${this.props.data.event ? this.props.data.event._instance.range.end.getMinutes() : '00'}:00`
        }
      })

      //If new event minus 1 aday to end date
      if(this.props.popupData.newEvent) {
        if (this.props.data.event) {
          var endDate = new Date(this.props.data.event._instance.range.end);
          endDate.setDate(endDate.getDate());
          this.setState({'endDate': endDate})
        }
      }
    }
  }

  render() {
    const eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: this.handleFileAdded.bind(this),
      removedfile: this.removeFile.bind(this),
    }
    var componentConfig = {
      iconFiletypes: ['.pdf', '.doc', '.txt', '.rtf', '.png', '.jpg'],
      showFiletypeIcon: true,
      postUrl: '/uploadHandler'
    };

    var djsConfig = {
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/png,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      autoProcessQueue: false
    };

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        color: '#17253a',
        fontSize: '13px',
        fontFamily: 'SourceSansPro-Regular',
        background: state.isSelected ? '#e9f3fe' : 'white',
        cursor: 'default',
        ':hover': {
          backgroundColor: '#e9f3fe',
        }
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      },
      control: base => ({
        ...base,
        border: 0,
        boxShadow: 'none'
      })
    }

    const options = [
      { label: '0:30am', value: '0:30:00' },
      { label: '1:00am', value: '01:00:00' },
      { label: '1:30am', value: '01:30:00' },
      { label: '2:00am', value: '02:00:00' },
      { label: '2:30am', value: '02:30:00' },
      { label: '3:00am', value: '03:00:00' },
      { label: '3:30am', value: '03:30:00' },
      { label: '4:00am', value: '04:00:00' },
      { label: '4:30am', value: '04:30:00' },
      { label: '5:00am', value: '05:00:00' },
      { label: '5:30am', value: '05:30:00' },
      { label: '6:00am', value: '06:00:00' },
      { label: '6:30am', value: '06:30:00' },
      { label: '7:00am', value: '07:00:00' },
      { label: '7:30am', value: '07:30:00' },
      { label: '8:00am', value: '08:00:00' },
      { label: '8:30am', value: '08:30:00' },
      { label: '9:00am', value: '09:00:00' },
      { label: '9:30am', value: '09:30:00' },
      { label: '10:00am', value: '10:00:00' },
      { label: '10:30am', value: '10:30:00' },
      { label: '11:00am', value: '11:00:00' },
      { label: '11:30am', value: '11:30:00' },
      { label: '12:00am', value: '12:00:00' },
      { label: '12:30pm', value: '12:30:00' },
      { label: '01:00pm', value: '13:00:00' },
      { label: '01:30pm', value: '13:30:00' },
      { label: '02:00pm', value: '14:00:00' },
      { label: '02:30pm', value: '14:30:00' },
      { label: '03:00pm', value: '15:00:00' },
      { label: '03:30pm', value: '15:30:00' },
      { label: '04:00pm', value: '16:00:00' },
      { label: '04:30pm', value: '16:30:00' },
      { label: '05:00pm', value: '17:00:00' },
      { label: '05:30pm', value: '17:30:00' },
      { label: '06:00pm', value: '18:00:00' },
      { label: '06:30pm', value: '18:30:00' },
      { label: '07:00pm', value: '19:00:00' },
      { label: '07:30pm', value: '19:30:00' },
      { label: '08:00pm', value: '20:00:00' },
      { label: '08:30pm', value: '20:30:00' },
      { label: '09:00pm', value: '21:00:00' },
      { label: '09:30pm', value: '21:30:00' },
      { label: '10:00pm', value: '22:00:00' },
      { label: '10:30pm', value: '22:30:00' },
      { label: '11:00pm', value: '23:00:00' },
      { label: '11:30pm', value: '23:30:00' },
      { label: '12:00pm', value: '24:00:00' }
    ]

    const dateToFormat = this.state.popup.data.date

    return (
      <div>
        <div className={this.state.popup.visible === true ? 'overlay open' : 'overlay'} onClick={(event) => this.closePopup()}/>

        <input type="file" ref={(ref) => this.handleUpload = ref} onChange={(e) => this.uploadImage(e)} hidden/>

        <div className="wrapper-popup">
          <div className={`${this.state.popup.visible === true ? 'popup open ' : 'popup '}
          ${this.props.popupType === 'pictures' ? 'pictures' : ''}
          ${this.props.popupType === 'agenda' ? 'agenda' : ''}
          ${this.props.popupType === 'reports' ? 'reports' : ''}
          ${this.props.popupType === 'delete' ? 'delete' : ''}
          ${(this.props.popupType === 'owner-details' || this.props.popupType === 'property-details') ? 'edit-property' : ''}
          ${this.props.popupType === 'owner-details' ? 'owner-details' : ''}
          ${this.props.popupType === 'sensors' ? 'sensors' : ''}
          ${this.props.popupType === 'tasks' ? 'tasks' : ''}`}
          >
            <div onClick={(event) => this.closePopup()} className="close"/>

            {/* Property */}
            {(this.props.popupType === 'owner-details' || this.props.popupType === 'property-details') ?
            <div className="row-fluid show">
              <EditProperty data={this.props.data} popupType={this.props.popupType}/>
            </div> : ''}

            {/* Reports */}
            {this.props.popupType === 'reports' ?
            <div className="row-fluid show">
              <Report data={this.props.data[0]}/>
            </div> : ''}

            {/* Sensors */}
            <div className={this.props.popupType === 'sensors'? 'row-fluid show' : 'hide'}>
              <h2>Sensors information</h2>
              <form>
                <label>Name</label>
                <input type="text" name="name" className="name align-right" onChange={(e) => this.handleUserInput(e)} value={this.state.popup.data !== '' ? this.state.popup.data.name : ''}/>
                {this.state.userStatus === 'Admin' ?
                  <div>
                    <label>Status</label>
                    <select name="status" className="status" defaultValue={this.props.data.status} onChange={(e) => this.handleUserInput(e)}>
                      <option value="On-site check" selected={this.state.popup.data.status === 'On-site check' ? 'selected' : ''}>On-site check</option>
                      <option value="Alarm activated" selected={this.state.popup.data.status === 'Alarm activated' ? 'selected' : ''}>Alarm activated</option>
                    </select>
                    <div className="row-fluid">
                      <div className="large-6 columns">
                        <label>Date</label>
                        <DatePicker
                          dateFormat="d'/'M'/'yyyy"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="large-6 columns">
                        <label>Time</label>
                        <Select
                          className="react-select-container"
                          classNamePrefix
                          value={this.state.timeStart}
                          styles={customStyles}
                          onChange={this.handleTimeStart}
                          placeholder="9:00am"
                          options={options}
                        />
                      </div>
                    </div>
                </div> :
                <div>
                  <label>Status</label>
                  <input type="text" className="status align-right" value={this.state.popup.data.status}/>
                  <div className="datepicker-not-admin">
                    <div className="row-fluid">
                      <div className="large-6 columns">
                        <label>Date</label>
                        <input type="text" name="date" value={date(dateToFormat)}/>
                      </div>
                      <div className="large-6 columns">
                        <label>Time</label>
                        <input type="text" name="time" value={time(dateToFormat)}/>
                      </div>
                    </div>
                  </div>
                </div>}

                <label>Details</label>
                <textarea
                  name="details"
                  className="details"
                  onChange={(event) => this.handleUserInput(event)}
                  value={this.state.popup.data.details !== '' ? this.state.popup.data.details : ''}
                />
              {this.state.userStatus === 'Admin' ?
                <div>
                  <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                </div> : ''}
              </form>
            </div>

            {/* Tasks */}
            <div className={this.props.popupType === 'tasks'? 'row-fluid show' : 'hide'}>
              <h2>Tasks</h2>
              <form>
                <label>Name</label>
                <input type="text" name="name" className="name align-right" onChange={(e) => this.handleUserInput(e)} value={this.state.popup.data !== '' ? this.state.popup.data.name : ''}/>
                {this.state.userStatus === 'Admin' ?
                  <div>
                    <label>Status</label>
                    <select name="status" className="status" defaultValue={this.props.data.status} onChange={(e) => this.handleUserInput(e)}>
                      <option value="Open" selected={this.state.popup.data.status === 'Open' ? 'selected' : ''}>Open</option>
                      <option value="Closed" selected={this.state.popup.data.status === 'Closed' ? 'selected' : ''}>Closed</option>
                      <option value="Ongoing" selected={this.state.popup.data.status === 'Ongoing' ? 'selected' : ''}>Ongoing</option>
                    </select>
                    <label>Date</label>
                    <DatePicker
                      dateFormat="d'/'M'/'yyyy"
                      selected={this.state.startDate}
                      onChange={this.handleChange}
                    />
                </div> :
                <div>
                  <label>Status</label>
                  <input type="text" className="status align-right" value={this.state.popup.data.status}/>
                  <label>Date</label>
                  <div className="datepicker-not-admin">
                  <DatePicker
                    dateFormat="d'/'M'/'yyyy"
                    selected={this.state.startDate}
                  />
                  </div>
                </div>}

                <label>Details</label>
                <textarea
                  name="details"
                  className="details"
                  onChange={(event) => this.handleUserInput(event)}
                  value={this.state.popup.data.details !== '' ? this.state.popup.data.details : ''}
                />
              {this.state.userStatus === 'Admin' ?
                <div>
                  <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                </div> : ''}
              </form>
            </div>

            {/* Pictures */}
            <div className={this.props.popupType === 'pictures'? 'row-fluid pictures show' : 'hide'}>
              <img src={this.props.data} alt="" onDragStart={this.preventDragHandler}/>
            </div>

            {/* Delete */}
            <div className={this.props.popupType === 'delete' ? 'row-fluid show' : 'hide' }>
              <h2 className="text-center">Are you sure to want to delete this?</h2>
              <div className="large-6 columns">
                <button onClick={(event) => this.deleteEntry(event)} className="btn-sixth">Delete</button>
              </div>
              <div className="large-6 columns">
                <button onClick={(event) => this.closePopup()}  className="btn-secondary">Cancel</button>
              </div>
            </div>

            {/* Users */}
            <div className={this.props.popupType === 'users' ? 'row-fluid show' : 'hide' }>
              <h2 className="margin-left">User</h2>
              <div className="auto columns">
                {this.state.popup.data.photoProfile ?
                  <div>
                    <button onClick={(e) => this.handleUpload.click()} className="btn-edit"/>
                    <div className="image-user" style={{backgroundImage: "url("+this.state.popup.data.photoProfile+")" }}/>
                  </div> :
                  <DropzoneComponent
                    className="dropzone"
                    eventHandlers={eventHandlers}
                    djsConfig={djsConfig}
                    config={componentConfig}
                  >
                    <div className="dz-message">Drop file to upload<br/> or</div>
                    <button className="btn">Select file</button>
                  </DropzoneComponent>
                }
              </div>
              <div className="width-minus-auto columns">
                <form>
                  <label>First name</label>
                  <input type="text" name="firstName" className="firstName align-right" value={this.state.popup.data.firstName} onChange={(e) => this.handleUserInput(e)}/>
                  <label>Last name</label>
                  <input type="text" name="lastName" className="lastName align-right" value={this.state.popup.data.lastName} onChange={(e) => this.handleUserInput(e)}/>
                  <label>Email</label>
                  <input type="text" name="email" className="email align-right" value={this.state.popup.data.email} onChange={(e) => this.handleUserInput(e)}/>
                  <label className={`password ${this.state.hidden}`}>Password</label>
                  <input type={this.state.hidden ? "password" : "text"} name="password" className="password align-right"
                    onClick={(e) => this.setState({ hidden: false })}
                    onBlur={(e) => this.setState({ hidden: true })}
                    onChange={(e) => {this.setState({field: 'text'}); this.handleUserInput(e)}}
                    value={this.state.popup.data.password}
                  />
                  <label>Status</label>
                  <select name="status" className="status" defaultValue={this.state.popup.data.status} onChange={(e) => this.handleUserInput(e)}>
                    <option value="Admin" selected={this.state.popup.data.status === 'Admin' ? 'selected' : ''}>Admin</option>
                    <option value="Collaborator" selected={this.state.popup.data.status === 'Collaborator' ? 'selected' : ''}>Collaborator</option>
                    <option value="Client" selected={this.state.popup.data.status === 'Client' ? 'selected' : ''}>Client</option>
                    <option value="Main contact" selected={this.state.popup.data.status === 'Main contact' ? 'selected' : ''}>Main contact</option>
                  </select>
                  <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()}  className="btn-secondary">Cancel</button>
                </form>
              </div>
            </div>

            {/* Contractual documents + Documents and photos */}
            <div className={ (this.props.popupType === 'contractualDocs' || this.props.popupType === 'documentsPhotos')? 'row-fluid show' : 'hide' }>
              <h2 className="margin-left">
                {this.props.popupType === 'contractualDocs' ? 'Contractual documents' : '' }
                {this.props.popupType === 'documentsPhotos' ? 'Documents and photos' : '' }
              </h2>
              <div className="large-5 medium-5 columns">
                {this.state.popup.data.fileURL ?
                <div>
                  <button className="btn-primary"><a href={this.state.popup.data.fileURL} target="_blank" rel="noopener noreferrer">Download file</a></button>
                </div>
                  :
                <DropzoneComponent
                  className="dropzone documentsPhotos"
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                  config={componentConfig}
                >
                  <p className="file-name">{this.state.file.name}</p>
                  <div className="dz-message">Drop file to upload<br/> or</div>
                  <button className="btn-fifth">Select file</button>
                </DropzoneComponent>}
              </div>
              <div className="large-7 medium-7 columns">
                {/* Contractual documents */}
                <form className={this.props.popupType === 'contractualDocs' ? 'show' : 'hide'}>
                  <label>Name</label>
                  <input type="text" name="name" className="name align-right" onChange={(e) => this.handleUserInput(e)} value={this.state.popup.data !== '' ? this.state.popup.data.name : ''}/>
                  <label>Date</label>
                  <DatePicker
                    dateFormat="d'/'M'/'yyyy"
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                  <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()}  className="btn-secondary">Cancel</button>
                </form>

                {/* Documents and photos */}
                <form className={this.props.popupType === 'documentsPhotos' ? 'show' : 'hide'}>
                  <label>Name</label>
                  <input type="text" name="name" className="name align-right" onChange={(e) => this.handleUserInput(e)} value={this.state.popup.data.name !== '' ? this.state.popup.data.name : ''}/>
                  <label>Category</label>
                  <select name="category" className="category" defaultValue={this.props.data.category} onChange={(e) => this.handleUserInput(e)}>
                    <option value="Invoice" selected={this.state.popup.data.category === 'Invoice' ? 'selected' : ''}>Invoice</option>
                    <option value="Administrative" selected={this.state.popup.data.category === 'Administrative' ? 'selected' : ''}>Administrative</option>
                    <option value="Tax" selected={this.state.popup.data.category === 'Tax' ? 'selected' : ''}>Tax</option>
                    <option value="Charges" selected={this.state.popup.data.category === 'Charges' ? 'selected' : ''}>Charges</option>
                    <option value="Quote" selected={this.state.popup.data.category === 'Quote' ? 'selected' : ''}>Quote</option>
                    <option value="Contract" selected={this.state.popup.data.category === 'Contract' ? 'selected' : ''}>Contract</option>
                    <option value="Maintenance" selected={this.state.popup.data.category === 'Maintenance' ? 'selected' : ''}>Maintenance</option>
                    <option value="Photo" selected={this.state.popup.data.category === 'Photo' ? 'selected' : ''}>Photo</option>
                  </select>
                  <label>Date</label>
                  <DatePicker
                    dateFormat="d'/'M'/'yyyy"
                    selected={this.state.startDate}
                    onChange={this.handleChange}
                  />
                  <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()}  className="btn-secondary">Cancel</button>
                </form>
              </div>
            </div>

            {/* Agenda */}
            {this.props.popupType === 'agenda' ?
            <div className="row-fluid agenda show">
              <div>
                <h2 className="margin-left">Event</h2>
                <span className={`fc-event ${this.props.data.event ? this.props.data.event._def.title.toLowerCase() : this.props.data.className}`}>{this.props.data.event ? this.props.data.event._def.title : this.props.data.title}</span>
              </div>
              <div className="large-6 medium-6 small-6 columns">
                <form>
                  <label>From</label>
                  <DatePicker
                    className="datepicker"
                    dateFormat="d'/'M'/'yyyy"
                    selected={this.state.startDate ? this.state.startDate : this.props.data.event._instance.range.start}
                    onChange={this.handleChange}
                  />
                  <label>To</label>
                  <DatePickerB
                    className="datepicker"
                    dateFormat="d'/'M'/'yyyy"
                    selected={this.state.endDate ? this.state.endDate : this.props.data.event._instance.range.end}
                    onChange={this.handleChangeB}
                  />
                </form>
              </div>
              <div className="large-6 medium-6 small-6 columns">
                <Select
                  className="react-select-container"
                  classNamePrefix
                  value={this.state.timeStart}
                  styles={customStyles}
                  onChange={this.handleTimeStart}
                  placeholder="9:00am"
                  options={options}
                />
                <Select
                  className="react-select-container"
                  classNamePrefix
                  value={this.state.timeEnd}
                  styles={customStyles}
                  onChange={this.handleTimeEnd}
                  placeholder="10:00am"
                  options={options}
                />
              </div>
              <div className="box-btns">
              {this.state.userStatus === 'Admin' ?
                <div>
                <button onClick={(event) => this.handleSave(event)} className="btn-sixth">Save</button>
                <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                <ul className="buttons">
                  <li onClick={(event) => this.deleteEvent(event)} className="delete"/>
                </ul>
              </div> : ''}
              </div>
            </div> : ''}

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
