import React from 'react';
import 'react-router-dom';
import database from '../firebase/firebase';
import { connect } from 'react-redux';
import { popup } from '../actions/popup';
import uuidv1 from 'uuid/v1';

export class AddProperty extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      field: '',
      hidden: true,
      popup: {
        popupType: ''
      },
      propertyID: '',
      newPropertyID: '',
      oldOwnerEmail: '',
      oldMainContactEmail: '',
      property: {
        propertyAddress: '',
        propertyCity: '',
        propertyPostCode: '',
        propertyCountry: '',
        ownerFirstName: '',
        ownerLastName: '',
        ownerEmail: '',
        ownerPassword: '',
        ownerAddress: '',
        ownerCity: '',
        ownerPostCode:'',
        ownerCountry: '',
        ownerPhone: '',
        mainContactFirstName: '',
        mainContactLastName: '',
        mainContactEmail: '',
        mainContactPassword: '',
        mainContactPhone: '',
        plan: '',
        photo: '',
        rooms: {}
      }
    }
  }
  handleUserInputText (e,field) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      ...prevState,
        property: {
          ...prevState.property,
          [name]: value
        }
      }
    ))
  }

  handleUserInputRooms (e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    const newState = []
    for (var i = 1; i < Number(value)+Number(1); ++i) {
      newState[i] = [name+' '+i]
    }
    var items = {}
    var self = this
    newState.forEach(function(v) {
      items[v] = {
        ...self.state.property.rooms[name].items,
        Note: ''
      }
    })

    this.setState((prevState) => ({
      ...prevState,
        property: {
          ...prevState.property,
          rooms: {
            ...prevState.property.rooms,
            [name]: {
              ...items,
              'items': prevState.property.rooms[name].items ? prevState.property.rooms[name].items : '',
              Quantity: value
            }
          }
        }
      }
    ))
  }

  closePopup (e) {
    var self = this
    self.props.popup({popupType: '', visible: false, data: '', popupEditProperty: false})
  }

  upload (e) {
    if (e.target.files[0]) {
      const self = this;
      const field = self.state.field;
      const property = self.state.property;

      const FR = new FileReader();
      FR.readAsDataURL(e.target.files[0]);
      FR.addEventListener('load', function(e) {
        if (self.state.propertyID) {
          database.ref(`properties/${self.state.propertyID}`).update({
            [field]: e.target.result
          })
        }
        if (field === 'plan') {
          self.setState({
            property: {
              ...self.state.property,
              rooms: property.rooms,
              photo: property.photo ? property.photo : '',
              [field]: e.target.result
            }
          });
        }
        if (field === 'photo') {
          self.setState({
            property: {
              ...self.state.property,
              rooms: property.rooms,
              plan: property.plan ? property.plan : '',
              [field]: e.target.result
            }
          });
        }
      });
    }
  }

  onSubmit(e){
    e.preventDefault()
    var self = this
    const ID = this.state.propertyID
    if(this.props.data) {
      console.log('old')
      database.ref(`properties/${this.props.data}`).update(this.state.property)
    } else if (this.state.newPropertyID) {
      database.ref(`properties/${this.state.newPropertyID}`).set(this.state.property)
    } else {
      console.log('new')
      const newID = uuidv1();
      database.ref(`properties/${newID}`).set(this.state.property)
      this.setState({
        newPropertyID: newID
      });
    }

    const property = this.state.property
    if (property.mainContactEmail) {
      const userRef = database.ref('users/' + property.mainContactEmail.replace(/[@._]/g, ''));
      userRef.update({
        firstName: property.mainContactFirstName ? property.mainContactFirstName : '',
        lastName: property.mainContactLastName ? property.mainContactLastName : '',
        email: property.mainContactEmail ? property.mainContactEmail : '',
        password: property.mainContactPassword ? property.mainContactPassword : '',
        status: 'Main contact'
      })
    }
    if (property.ownerEmail) {
      const userRef = database.ref('users/' + property.ownerEmail.replace(/[@._]/g, ''));
      userRef.update({
        firstName: property.ownerFirstName ? property.ownerFirstName : '',
        lastName: property.ownerLastName ? property.ownerLastName : '',
        email: property.ownerEmail ? property.ownerEmail : '',
        password: property.ownerPassword ? property.ownerPassword : '',
        status: 'Client'
      })
    }

    //Delete user if his email is not attribued to a property
    if (this.state.oldMainContactEmail) {
      database.ref('properties').orderByChild('mainContactEmail').equalTo(this.state.oldMainContactEmail).once('value', (snapshot) => {
        if (snapshot.exists()){
        } else {
          const mainContactEmailToDelete = this.state.oldMainContactEmail.replace(/[@._]/g, '')
          const userRef = database.ref('users/' + mainContactEmailToDelete)
          userRef.remove()
        }
      })
    }
    if(this.state.oldOwnerEmail) {
      database.ref('properties').orderByChild('ownerEmail').equalTo(this.state.oldOwnerEmail).once('value', (snapshot) => {
        if (snapshot.exists()){
        } else {
          const oldOwnerEmailToDelete = this.state.oldOwnerEmail.replace(/[@._]/g, '')
          const userRef = database.ref('users/' + oldOwnerEmailToDelete)
          userRef.remove()
        }
      })
    }

    if (window.location.href.indexOf('add-property') > -1) {
      self.props.history.push('/dashboard');
    } else {
      this.props.popup({
        popupEditProperty: false,
        visible: false,
        updated: true
      });
      this.setState({
        popup: {
          visible: false
        }
      })
    }
  }

  componentDidMount(){
    const ID = this.state.propertyID;
    database.ref(`properties/${ID ? ID : this.props.data}`).once('value', (snapshot) => {
      const val = snapshot.val()
      this.setState({
        oldOwnerEmail: val ? val.ownerEmail : '',
        oldMainContactEmail: val ? val.mainContactEmail : '',
        propertyID: ID,
        property: val,
        popupType: this.props.popupType ? this.props.popupType : ''
      });

      if (this.props.data === undefined) {
        this.setState((prevState) => ({
          ...prevState,
            property: {
              ...prevState.property,
              rooms: {
                Entrance: {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps':'',
                    'Window(s) & window shutters': '',
                    'Door(s)':'',
                    'Furniture(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                "Living room": {
                  items: {
                    'Walls, floor & carpet': '',
                    'Electric switches & lamps': '',
                    'Window(s) & window shutters': '',
                    'Curtain(s)': '',
                    'Door(s)': '',
                    'Cupboard(s)': '',
                    'Fireplace - Mirror': '',
                    'Sofa(s) & armchair(s)': '',
                    'Table(s)': '',
                    'Television': '',
                    "Note": ""
                  },
                  Quantity: 0
                },
                "Dining room": {
                  items: {
                    'Walls floor & carpet':'',
                    'Electric switches & lamps':'',
                    'Window(s) & window shutters':'',
                    'Door(s)':'',
                    'Dining table':'',
                    'Dining chair(s)':'',
                    'Cupboard(s)':'',
                    'Fireplace - Mirror(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Kitchen: {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s)':'',
                    'Door(s) check':'',
                    'Built-in cupboards':'',
                    'Chair(s)':'',
                    'Fridge & Wine cellar':'',
                    'Gas fired boiler':'',
                    'Hot water tank':'',
                    'Water softener':'',
                    'Sink(s) & tap(s)':'',
                    'Smoke detector':'',
                    'Gas meter':'',
                    'Electricity meter':'',
                    'Electrical goods':'',
                    'Fireplace - Mirror(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Bedroom: {
                  items: {
                    'Walls, floor & carpet':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s) & window shutters':'',
                    'Curtain(s)':'',
                    'Door(s)':'',
                    'Built-in cupboards':'',
                    'Dresser(s) & Chair(s)':'',
                    'Fireplace - Mirror(s)':'',
                    'Safe':'',
                    'Television':'',
                    'Smoke detector':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Dressing: {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s) & window shutters':'',
                    'Curtain(s)':'',
                    'Door(s)':'',
                    'Built-in cupboards':'',
                    'Dresser(s) & Chair(s)':'',
                    'Mirror(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Bathroom: {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s)':'',
                    'Curtain(s)':'',
                    'Door(s)':'',
                    'Shelf(s)':'',
                    'Bath - Shower':'',
                    'Washbasin - Toilet - Bidet':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                "Staff room": {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s)':'',
                    'Curtain(s)':'',
                    'Door(s)':'',
                    'Shelf(s)':'',
                    'Bath - Shower':'',
                    'Washbasin - Toilet - Bidet':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                "WC & wash basin": {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Shelf(s)':'',
                    'Toilet':'',
                    'Sink(s) & tap(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Corridor: {
                  items: {
                    'Walls & floor':'',
                    'Electric switches & lamps & spotlight':'',
                    'Window(s) & window shutters':'',
                    'Curtain(s)':'',
                    'Door(s)':'',
                    'Built-in cupboards':'',
                    'Dresser(s) & Chair(s)':'',
                    'Mirror(s)':'',
                    "Note": ""
                  },
                  Quantity: 0
                },
                Balcony: {
                  Quantity: 0
                },
                Terrace: {
                  Quantity: 0
                },
                Gym: {
                  Quantity: 0
                },
                "Swimming pool": {
                  Quantity: 0
                },
                Garage: {
                  Quantity: 0
                },
                Cellar: {
                  Quantity: 0
                }
              }
            }
          }
        ))
      }

    })
  }

  render() {
    const property = this.state.property ? this.state.property : ''
    const rooms = this.state.property ? this.state.property.rooms : ''
    const field = this.state.field ? this.state.field : ''

    return (
      <div>
        <div className="wrapper">
          <main className={`add-property ${this.props.popupType === 'owner-details' ? 'owner-details' : this.props.popupType === 'property-details' ? 'property-details' : ''}`}>
            <section className="section-1">
              <div className="container">
                <div className="box-item no-hover padding">
                  <h2>Owner details</h2>
                    <div className="row-fuild">
                      <div className="large-6 medium-6 columns">
                        <form>
                          <label>First name</label>
                          <input type="text" name="ownerFirstName" className="ownerFirstName align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerFirstName}/>
                          <label>Email</label>
                          <input type="text" name="ownerEmail" className="ownerEmail align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerEmail}/>
                          <label className={`password ${this.state.hidden}`}>Password</label>
                          <input type={this.state.hidden ? "password" : "text"} name="ownerPassword" className="ownerPassword align-right"
                            onClick={(e) => this.setState({ hidden: false })}
                            onBlur={(e) => this.setState({ hidden: true })}
                            onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}}
                            value={property.ownerPassword}
                          />
                          <label>Contact address</label>
                          <input type="text" name="ownerAddress" className="ownerAddress align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerAddress}/>
                          <label>City</label>
                          <input type="text" name="ownerCity" className="ownerCity align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerCity}/>
                        </form>
                      </div>
                      <div className="large-6 medium-6 columns">
                        <form>
                          <label>Last name</label>
                          <input type="text" name="ownerLastName" className="ownerLastName align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerLastName}/>
                          <label>Phone number</label>
                          <input type="text" name="ownerPhone" className="ownerPhone align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerPhone}/>
                          <label className="postcode">Postcode</label>
                          <input type="text" name="ownerPostCode" className="ownerPostcode align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerPostCode}/>
                          <label>Country</label>
                          <input type="text" name="ownerCountry" className="ownerCountry align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.ownerCountry}/>
                        </form>
                      </div>
                    </div>
                  <button onClick={(e) => this.onSubmit(e)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                </div>
              </div>
            </section>

            <section className="section-2">
              <div className="container">
                <div className="box-item no-hover padding">
                  <h2>Main contact details</h2>
                    <div className="row-fuild">
                      <div className="large-6 medium-6 columns">
                        <form>
                          <label>First name</label>
                          <input type="text" name="mainContactFirstName" className="mainContactFirstName align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.mainContactFirstName}/>
                          <label>Email</label>
                          <input type="text" name="mainContactEmail" className="mainContactEmail align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.mainContactEmail}/>
                          <label className={`password ${this.state.hidden}`}>Password</label>
                          <input type={this.state.hidden ? "password" : "text"} name="mainContactPassword" className="mainContactPassword align-right"
                            onClick={(e) => this.setState({ hidden: false })}
                            onBlur={(e) => this.setState({ hidden: true })}
                            onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}}
                            value={property.mainContactPassword}
                          />
                        </form>
                      </div>
                      <div className="large-6 medium-6 columns">
                        <form>
                          <label>Last name</label>
                          <input type="text" name="mainContactLastName"className="mainContactLastName align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.mainContactLastName}/>
                          <label>Phone number</label>
                          <input type="text" name="mainContactPhone" className="mainContactPhone align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.mainContactPhone}/>
                        </form>
                      </div>
                    </div>
                  <button onClick={(e) => this.onSubmit(e)} className="btn-sixth">Save</button>
                  <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                </div>
              </div>
            </section>

            <section className="section-3">
              <div className="container">
                <div className="box-item no-hover padding">
                  <input type="file" ref={(ref) => this.handleUpload = ref} onChange={(e) => this.upload(e)} hidden/>
                  <div className="row-fuild">
                    <div className="large-6 medium-6 columns">
                      <div className="box-thumbnail full-border-radius align-left" style={{backgroundImage: "url("+property.photo+")" }}/>
                      <div className="box-bottom align-right">
                        <div>
                          <h2>Photo</h2>
                          <button onClick={(e) => {this.setState({field: 'photo'}); this.handleUpload.click()}} className="btn-fourth">Select file</button>
                        </div>
                      </div>
                    </div>
                    <div className="large-6 medium-6 columns">
                      <div className="box-small-plan full-border-radius align-left" style={{backgroundImage: "url("+property.plan+")" }}/>
                      <div className="box-bottom align-right">
                        <div>
                          <h2>Plan</h2>
                          <button onClick={(e) => {this.setState({field: 'plan'}); this.handleUpload.click()}} className="btn-fourth">Select file</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form className="rooms">
                    <h2>Property details</h2>
                    <div className="row-fuild">
                      <div className="large-6 medium-6 columns">
                        <label>Contact address</label>
                        <input type="text" name="propertyAddress" className="propertyAddress align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.propertyAddress}/>
                        <label>City</label>
                        <input type="text" name="propertyCity" className="propertyCity align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.propertyCity}/>
                      </div>
                      <div className="large-6 medium-6 columns">
                        <label>Postcode</label>
                        <input type="text" name="propertyPostCode" className="propertyPostCode align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.propertyPostCode}/>
                        <label>Country</label>
                        <input type="text" name="propertyCountry" className="propertyCountry align-right" onChange={(e) => {this.setState({field: 'text'}); this.handleUserInputText(e, field)}} value={property.propertyCountry}/>
                      </div>
                    </div>
                  </form>
                  <div className="row-fuild">
                    <div className="large-6 medium-6 columns">
                      <div className="row-fuild">
                        <div className="large-6 medium-6 columns">
                          <table className="blanc owner-details">
                            <thead>
                              <tr>
                                <th><span>Rooms</span></th>
                                <th><span className="align-right">Quantity</span></th>
                              </tr>
                            </thead>

                            <tbody>
                              { Object.keys(rooms).sort((a, b) => a > b).slice(0,4).map((key,item, i) => {
                                  return (
                                    <tr>
                                      <td><span>{key}</span></td>
                                      <td><input type="number" name={key} onChange={(e) => {this.setState({field: 'rooms'}); this.handleUserInputRooms(e, field)}} value={rooms[key].Quantity}/></td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="large-6 medium-6 columns">
                          <table className="blanc owner-details">
                            <thead>
                              <tr>
                                <th><span>Rooms</span></th>
                                <th><span className="align-right">Quantity</span></th>
                              </tr>
                            </thead>
                            <tbody>
                              { Object.keys(rooms).sort((a, b) => a > b).slice(4,8).map((key,item, i) => {
                                  return (
                                    <tr>
                                      <td><span>{key}</span></td>
                                      <td><input type="number" name={key} onChange={(e) => {this.setState({field: 'rooms'}); this.handleUserInputRooms(e, field)}} value={rooms[key].Quantity}/></td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="large-6 medium-6 columns">
                      <div className="row-fuild">
                        <div className="large-6 medium-6 columns">
                          <table className="blanc owner-details">
                            <thead>
                              <tr>
                                <th><span>Rooms</span></th>
                                <th><span className="align-right">Quantity</span></th>
                              </tr>
                            </thead>
                            <tbody>
                              { Object.keys(rooms).sort((a, b) => a > b).slice(8,12).map((key,item, i) => {
                                  return (
                                    <tr>
                                      <td><span>{key}</span></td>
                                      <td><input type="number" name={key} onChange={(e) => {this.setState({field: 'rooms'}); this.handleUserInputRooms(e, field)}} value={rooms[key].Quantity}/></td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="large-6 medium-6 columns">
                          <table className="blanc owner-details">
                            <thead>
                              <tr>
                                <th><span>Rooms</span></th>
                                <th><span className="align-right">Quantity</span></th>
                              </tr>
                            </thead>
                            <tbody>
                              { Object.keys(rooms).sort((a, b) => a > b).slice(12,16).map((key,item, i) => {
                                  return (
                                    <tr>
                                      <td><span>{key}</span></td>
                                      <td><input type="number" name={key} onChange={(e) => {this.setState({field: 'rooms'}); this.handleUserInputRooms(e, field)}} value={rooms[key].Quantity}/></td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <button onClick={(e) => this.onSubmit(e)} className="btn-sixth">Save</button>
                    <button onClick={(event) => this.closePopup()} className="btn-secondary">Cancel</button>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProperty);
