import React from 'react';
import database from '../firebase/firebase';

export class About extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      about: [],
      aboutPhoto: ''
    }
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  componentDidMount(){
    database.ref('about').once('value', (snapshot) => {
      const val = snapshot.val()
      const newState = [];

      if (snapshot.exists()) {
        const descriptionsAbout = snapshot.child('description').val();
        if (descriptionsAbout) {
          for (let item in descriptionsAbout) {
            newState.push({
              title: descriptionsAbout[item].title,
              text: descriptionsAbout[item].text,
              fileURL: descriptionsAbout[item].fileURL,
              fileName: descriptionsAbout[item].fileName
            });
          }
          this.setState({
            about: newState
          });
        }
        if (snapshot.child('photo').val()) {
          this.setState({
            aboutPhoto: snapshot.child('photo').val()
          });
        }
      }
    });
  }

  render() {
    return (
      <div>
        <div className="wrapper">
          <main className="about">
            <section className="section-1">
              <div className="container">
                <div className="row-fluid">
                  <div className="large-6 columns">
                    <div className="big-about-photo" style={{backgroundImage: "url("+this.state.aboutPhoto+")" }}/>
                  </div>
                  <div className="large-6 columns">

                    {this.state.about.sort(function(a, b) {
                        return a.title < b.title ? 1 : (a.title > b.title ? -1 : 0);
                        }).map((item) => {
                        return (
                          <div key={item.title} className="box-item no-hover padding">
                            <form>
                              <h2>{item.title}</h2>
                              <p className="desc-about">{item.text}</p>
                              {item.fileURL ? <a className="btn btn-sixth" href={item.fileURL} rel="noopener noreferrer" target="_blank">Download our PDF</a> : ''}
                            </form>
                          </div>
                        )
                      }
                    )}

                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default About;
