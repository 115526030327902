import React from 'react'
import database from '../firebase/firebase'
import { connect } from 'react-redux'
import { popup } from '../actions/popup'
import { signup } from '../actions/signup'
import Documents from './Documents'
import SensorsInformation from './SensorsInformation'
import ReportsTasks from './LuxusReports'
import Agenda from './Agenda'

export class Property extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupEditProperty: false,
      propertyID: this.props.location.id,
      property: {
        contractualDocs: [],
        documentsPhotos: [],
        reports: [],
        tasks: [],
        sensors: [],
        rooms: []
      },
      users: [],
      currentcontractualDocs: 0
    }
    this.date = this.date.bind(this)
    this.pageSize = 8;
  }

  calendarComponentRef = React.createRef();

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  handleNavigation(e, index) {
    e.persist()
    const pagesCountContractualDocs = Math.ceil(Object.keys(this.state.property.contractualDocs).length / this.pageSize);
    this.setState({
      currentcontractualDocs: index < 0 ? 0 : index >= pagesCountContractualDocs ? (index - 1) : index
    });
  }

  date(dateToFormat) {
    const date = new Date(dateToFormat);
    date.setDate(date.getDate());
    const mois = [
      "Jan", "Feb", "March",
      "Avr", "May", "June", "Jul",
      "August", "Sept", "Oct",
      "Nov", "Dec"
    ];
    const dateReformat = date.getFullYear() + ' ' + mois[date.getMonth()] + '. ' + date.getDate();
    return dateReformat
  }

  onPopupOwnerDetails(e) {
    e.preventDefault();
    this.props.popup({
      popupType: 'owner-details',
      visible: true,
      data: this.state.propertyID
    })
    this.setState({
      popupEditProperty: true
    })
  }

  onPopupPropertyDetails(e) {
    e.preventDefault();
    this.props.popup({
      popupType: 'property-details',
      visible: true,
      data: this.state.propertyID
    })
    this.setState({
      popupEditProperty: true
    })
  }

  onPopupContracualDocs(data, key){
    if (key) {
      this.props.popup({
        propertyID: this.state.propertyID,
        ID: key,
        popupType: 'contractualDocs',
        visible: true,
        data: data
      });
    } else {
      this.props.popup({
        propertyID: this.state.propertyID,
        popupType: 'contractualDocs',
        visible: true,
        data: {
          author: this.state.user,
          name: '',
          date: ''
        }
      });
    }
  }

  onPopupPictures(data){
    this.props.popup({
      popupType: 'pictures',
      visible: true,
      data: data
    });
  }

  onDelete(e, key) {
    e.preventDefault()
    this.props.popup({
      propertyID: this.props.id,
      popupType: 'delete',
      field: this.state.field === 'property' ? 'property' : 'contractualDocs',
      visible: true,
      data: key
    });
  }

  componentWillReceiveProps(nextProps, popupData) {
    if (nextProps.popupData.updated) {
      this.componentDidMount()
    }
    if(nextProps.popupData.popupEditProperty === false) {
      this.setState({
        popupEditProperty: false
      })
    }
  }

  componentDidMount() {
    if (this.props.location.id) {
      localStorage.setItem('idProperty', this.props.location.id);
    }

    const ID = localStorage.getItem('idProperty');
    database.ref(`properties/${ID}`).once('value', (snapshot) => {
      const val = snapshot.val()
      this.setState({
        propertyID: ID,
        property: val
      })
    })

    //Get users profile pictures
    database.ref('users').once('value', (snapshot) => {
      const val = snapshot.val()
      this.setState({
        users: val
      });
    });

    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
        user: user.firstName + ' ' + user.lastName,
        userStatus: user.status
      }
    ))
  }

  render() {
    const property = this.state.property ? this.state.property : '';
    const contractualDocs = this.state.property ? property.contractualDocs : ''
    const { currentcontractualDocs } = this.state;
    const rooms = this.state.property ? property.rooms : '';

    return (
      <div>
        <div className={`wrapper ${this.state.popupEditProperty === true ? 'popup-open' : ''}`}>
          <main className="property">
            <section className="section-1">
              <div className="container">
                <div className="row-fluid">
                  <div className="large-6 medium-12 columns">
                    <div className="box-item no-hover">
                      <div className="box-thumbnail" style={{backgroundImage: "url("+property.photo+")" }}/>
                      <div className="box-description with-edit">
                        <form>
                          {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopupPropertyDetails(event)} className="btn-edit align-right"/> : ''}
                          <label className="s-size">Property address</label>
                          <p className="address big-stone">{property.propertyAddress}, {property.propertyPostCode} {property.propertyCity}, {property.propertyCountry}</p>
                          <hr/>
                          {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopupOwnerDetails(event)} className="btn-third align-right">Edit</button> : ''}
                          <label className="s-size">Owner</label>
                          <p className="name big-stone">{property.ownerFirstName} {property.ownerLastName}</p>
                          <label className="s-size">Owner address</label>
                          <p className="address big-stone">{property.ownerAddress}, {property.ownerCity}, {property.ownerCountry}</p>
                          <label className="s-size">Owner email</label>
                          <p className="email big-stone">{property.ownerEmail}</p>
                          <label className="s-size">Owner phone number</label>
                          <p className="tel big-stone">{property.ownerPhone}</p>
                          <hr/>
                          <label className="s-size">Main contact</label>
                          <p className="name big-stone">{property.mainContactFirstName} {property.mainContactLastName}</p>
                          <label className="s-size">Main contact email</label>
                          <p className="email big-stone">{property.mainContactEmail}</p>
                          <label className="s-size">Main contact phone number</label>
                          <p className="tel big-stone">{property.mainContactPhone}</p>
                        </form>
                        {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.setState({field: 'property'}, () => {const key = this.state.propertyID; this.onDelete(event,key)})} className="btn-third delete align-right">Delete property</button> : ''}
                      </div>
                    </div>
                  </div>
                  <div className="large-6 medium-12 columns">
                    <div className="box-item no-hover">
                      <div className="box-description">
                        <h2>Contractual documents</h2>
                        {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopupContracualDocs(event)} className="btn-third align-right">Add new</button> : ''}
                          <table className="contractual-documents">
                            <thead>
                              <tr>
                                <th><span>Name</span></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              { contractualDocs ? Object.keys(contractualDocs).slice(currentcontractualDocs * this.pageSize, (currentcontractualDocs + 1) * this.pageSize).map((key, item, i) => {
                                const dateToFormat = contractualDocs[key].date
                                const data = contractualDocs[key]
                                  return (
                                    <tr key={`${i} ${key}`}>
                                      <td><span>{contractualDocs[key].name}</span><br/>{this.date(dateToFormat)}</td>
                                      <td></td>
                                      <td>
                                        <ul className="buttons">
                                          {contractualDocs[key].fileURL ? <li onClick={e => window.open(contractualDocs[key].fileURL)} className="view"/> : ''}
                                          {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onPopupContracualDocs(data, key)} className="edit"></li>: ''}
                                          {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onDelete(event, key)} className="delete"></li> : ''}
                                        </ul>
                                      </td>
                                    </tr>
                                  )
                                }
                              ) : ''}
                            </tbody>
                          </table>
                          { contractualDocs ?
                            <div>
                              <button
                                className={`${"btn-prev cararra"} ${currentcontractualDocs > 0 ? ' checked' : ''}`}
                                onClick={e => this.handleNavigation(e, currentcontractualDocs -1)}
                                previous
                              />
                              <button
                                className={`${"btn-next cararra"} ${currentcontractualDocs < Math.ceil(Object.keys(this.state.property.contractualDocs).length / this.pageSize) - 1 ? ' checked' : ''}`}
                                onClick={e => this.handleNavigation(e, currentcontractualDocs +1)}
                                next
                              />
                            </div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {this.state.userStatus !== 'Client' ?
              <div>
                <Documents id={this.props.location.id}/>
                <ReportsTasks id={this.props.location.id}/>
                <Agenda id={this.props.location.id}/>
                <SensorsInformation id={this.props.location.id}/>
              </div> : ''}

            <section className="section-3 rooms">
              <div className="container">
                <div className="box-item no-hover padding with-edit">
                  <div className="row-fluid">
                    <div className="large-2 columns">
                      <h2 className="align-left">Plan</h2>
                      {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopupPropertyDetails(event)} className="btn-edit align-right"/> : ''}
                      <table className="rooms">
                        <thead>
                          <tr>
                            <th><span>Rooms</span></th>
                            <th><span>Quantity</span></th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            rooms ? Object.keys(rooms).map((key, item, i) => {
                              if (rooms[key].Quantity !== 0) {
                                if (rooms[key].Quantity !== '0') {
                                  return (
                                    <tr key={`${i} ${key}`}>
                                      <td><span>{key}</span></td>
                                      <td><span className="black big-stone">{rooms[key].Quantity}</span></td>
                                    </tr>
                                  )
                                }
                              }
                            }
                          ) : ''}
                        </tbody>
                      </table>
                    </div>
                    <div className="large-10 columns">
                      <div className="box-big-plan" style={{backgroundImage: "url("+property.plan+")" }}>
                        {this.state.userStatus === 'Admin' && property.plan ?
                        <ul className="buttons">
                          <li onClick={(event) => {const data = property.plan; this.onPopupPictures(data)}} className="view"></li>
                        </ul> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({signup: {user}, popup: {popupData}}) => ({
  user,
  popupData
})

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData})),
  signup: (user) => dispatch(signup({user}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Property)
