import React from 'react';
import database from '../firebase/firebase';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { popup } from '../actions/popup';
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/core/main.css';

export class Agenda extends React.Component {
  calendarComponentRef = React.createRef();
  constructor(props) {
    super(props)
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      view: 1,
      location: '',
      propertyID: this.props.id,
      calendarWeekends: true,
      calendarEvents: [],
      btnAgendaMobile: false,
      events: [
        { title: "Cleaning", className: "cleaning", id: "1", backgroundColor: '#4cde8c' },
        { title: "Admin", className: "admin", id: "2", backgroundColor: '#de4670' },
        { title: "Inspection", className: "inspection", id: "3", backgroundColor: '#de974b' },
        { title: "Owner stay", className: "owner-details", id: "4", backgroundColor: '#4676dd' },
        { title: "Guest stay", className: "guest-stay", id: "5", backgroundColor: '#4bc4de' },
        { title: "Maintenance", className: "maintenance", id: "6", backgroundColor: '#904add' }
      ]
    }
  }

  date(dateToFormat) {
    const date = new Date(dateToFormat);
    date.setDate(date.getDate());
    const mois = [
      "Jan", "Feb", "Mars",
      "Avr", "May", "June", "Jul",
      "August", "Sept", "Oct",
      "Nov", "Dec"
    ];
    const dateReformat = date.getFullYear() + ' ' + mois[date.getMonth()] + '. ' + date.getDate();
    return dateReformat
  }

  componentWillReceiveProps(nextProps, popupData) {
    if (nextProps.popupData.deleted === true) {
      this.componentWillMount()
    }
    if (nextProps.popupData.updated === true) {
      this.componentWillMount()
      // const key = 'id';
      // const index = this.state.calendarEvents.findIndex(elem => elem[key] == nextProps.popupData.updatedEventID)
      //
      // const newState = [...this.state.calendarEvents]
      // const removeIndex = newState.map(function(item) { return item.id; }).indexOf(nextProps.popupData.updatedEventID);
      //
      // newState.splice(removeIndex, 1);
      // newState.push(nextProps.popupData.dataEventUpdate)
      // this.setState({calendarEvents: newState})
    }
  }

  componentWillMount() {
    if (this.props.id) {
      localStorage.setItem('idProperty', this.props.id);
    }
    const ID = localStorage.getItem('idProperty');
    database.ref(`properties/${ID}/agenda`).once('value', (snapshot) => {
      const val = snapshot.val()
      const newState = [];
      for (let item in val) {
        newState.push({
          id: val[item].id,
          title: val[item].title,
          start: val[item].start,
          end: val[item].end,
          backgroundColor: val[item].backgroundColor
        });
      }
      this.setState({calendarEvents: newState});
    })
  }

  componentDidMount() {
    //Get logged user
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
      user: user.firstName + ' ' + user.lastName,
      userStatus: user.status
      }
    ))

    if (this.props.location !== undefined) {
      if(this.props.location.pathname === '/agenda') {
        this.setState({
          location: '/agenda'
        });
      }
    }
    //Calendar
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function(eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        let backgroundColor = eventEl.getAttribute("backgroundcolor");
        return {
          title: title,
          id: id,
          backgroundColor: backgroundColor
        };
      }
    })
  }

  eventClick = (event) => {
    if(this.state.userStatus === 'Admin') {
      this.popup(event)
    }
  }
  eventReceive = (event) => {
    event.event.remove()
    if(this.state.userStatus === 'Admin') {
      this.popup(event)
    }
  }
  popup (event) {
    this.props.popup({
      popupType: 'agenda',
      visible: true,
      newEvent: true,
      data: event
    })
  }
  popupMobile (e, title, backgroundColor, className) {
    this.props.popup({
      popupType: 'agenda',
      visible: true,
      newEvent: true,
      data: {
        title: backgroundColor,
        backgroundColor: title,
        className: className
      }
    })
  }

  timeGridDay = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();;
    calendarApi.changeView('timeGridDay')
    this.setState({view: 3});
  }
  timeGridWeek = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();;
    calendarApi.changeView('timeGridWeek')
    this.setState({view: 2});
  }
  dayGridMonth = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();;
    calendarApi.changeView('dayGridMonth')
    this.setState({view: 1});
  }
  listWeek = () => {
    let calendarApi = this.calendarComponentRef.current.getApi();;
    calendarApi.changeView('listWeek')
    this.setState({view: 4});
  }

  render() {
    return (
      <div>
        <div className={ this.state.location === '/agenda' ? 'wrapper agenda' : '' }>
          <section className="section-5 section-agenda">
            <div className="container">
              <div className="box-item no-hover padding">
                <div id="btn-agenda-mobile"
                  onClick={(event) => this.setState({
                    btnAgendaMobile: !this.state.btnAgendaMobile
                  })}
                  >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <ul className={`menu-agenda ${this.state.btnAgendaMobile === true ? 'open' : ''}`}>
                  <li className={this.state.view === 1 ? 'checked' : '' } onClick={this.dayGridMonth}>Month</li>
                  <li className={this.state.view === 2 ? 'checked' : '' } onClick={this.timeGridWeek}>Week</li>
                  <li className={this.state.view === 3 ? 'checked' : '' } onClick={this.timeGridDay}>Day</li>
                  <li className={this.state.view === 4 ? 'checked' : '' } onClick={this.listWeek}>List</li>
                </ul>
                <div className="fc-drag-drop" id="external-events">
                  <h2>Agenda</h2>
                  {this.state.userStatus === 'Client' || this.state.userStatus === 'Main contact' ?
                    <div>
                      <p className="s-size slate-gray">Send us a message if you wish to add an event onto your property agenda.</p>
                      <button className="btn-secondary contact"><NavLink to="/contact">Contact us</NavLink></button>
                    </div>
                    :
                    <div>
                    <p className="s-size slate-gray">Drag into the calendar</p>
                    {this.state.events.map(event => {
                      const title = event.title;
                      const backgroundColor = event.backgroundColor;
                      const className = event.className
                      return (
                        <div
                          className={`fc-event + ${event.className}`}
                          title={event.title}
                          data={event.id}
                          backgroundcolor={event.backgroundColor}
                          key={event.id}
                          onClick={(e) => this.popupMobile(e,backgroundColor,title,className)}
                        >
                          {event.title}
                        </div>
                      )
                    })}
                  </div>}

                </div>
                <FullCalendar
                  className="calendar"
                  defaultView="dayGridMonth"
                  header={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
                  }}
                  customButtons={{
                    custom: {
                      click() {
                        this.timeGridDay()
                        this.dayGridMonth()
                        this.dayGridMonth()
                        this.listWeek()
                      },
                    },
                  }}
                  lazyFetching={true}
                  eventOverlap={true}
                  timezone="local"
                  displayEventTime={false}
                  plugins={[listPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  ref={this.calendarComponentRef}
                  weekends={this.state.calendarWeekends}
                  events={this.state.calendarEvents}
                  dateClick={this.handleDateClick}
                  eventClick={this.eventClick}
                  droppable={true}
                  editable={false}
                  firstDay={1}
                  eventReceive={this.eventReceive}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
