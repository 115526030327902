import React from 'react';
import database from '../firebase/firebase';
import { connect } from 'react-redux';
import { popup } from '../actions/popup';

export class LuxusReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item: '',
      edit: false,
      location: '',
      propertyID: this.props.id,
      property: {
        tasks: [],
        reports: []
      },
      type:'',
      currentPageReports: 0,
      currentPageTasks: 0
    }
    this.pageSize = 5;
  }

  handleNavigation(e, index) {
    e.persist()
    const pagesCountTasks = Math.ceil(Object.keys(this.state.property.tasks).length / this.pageSize);
    const pagesCountReports = Math.ceil(Object.keys(this.state.property.reports).length / this.pageSize);
    if(this.state.item === 'tasks') {
      this.setState({
        currentPageTasks: index < 0 ? 0 : index >= pagesCountTasks ? (index - 1) : index
      });
    } else if(this.state.item === 'reports') {
      this.setState({
        currentPageReports: index < 0 ? 0 : index >= pagesCountReports ? (index - 1) : index
      });
    }
  }

  date(dateToFormat) {
    const date = new Date(dateToFormat);
    date.setDate(date.getDate());
    const mois = [
      "Jan", "Feb", "March",
      "Avr", "May", "June", "Jul",
      "August", "Sept", "Oct",
      "Nov", "Dec"
    ];
    const dateReformat = date.getFullYear() + ' ' + mois[date.getMonth()] + '. ' + date.getDate();
    return dateReformat
  }

  datePDFReport(dateToFormat) {
    const MyDate = new Date(dateToFormat);
    MyDate.setDate(MyDate.getDate() + 0);
    const MyDateString = ('0' + (MyDate.getMonth()+1)).slice(-2) + '/'
           + ('0' + MyDate.getDate()).slice(-2) + '/'
           + MyDate.getFullYear();
    return MyDateString
  }

  onDelete(e, id) {
    e.preventDefault()
    this.props.popup({
      propertyID: this.props.id,
      popupType: 'delete',
      field: this.state.type,
      visible: true,
      data: id
    });
  }

  onPopupTasks(data, key){
    if (key) {
      this.props.popup({
        propertyID: this.state.propertyID,
        ID: key,
        popupType: 'tasks',
        visible: true,
        data: data
      });
    } else {
      this.props.popup({
        propertyID: this.state.propertyID,
        popupType: 'tasks',
        visible: true,
        data: {
          author: this.state.user,
          status: 'Open',
          name: '',
          date: '',
          details: ''
        }
      });
    }
  }

  onPopupReports(data, key){
    database.ref(`properties/${this.state.propertyID}/`).once('value', (snapshot) => {
      const val = snapshot.val()
      const newState = []
      const user = JSON.parse(localStorage.getItem('user'))

      newState.push({
        edit: this.state.edit,
        propertyID: this.state.propertyID,
        reportID: key !== '' ? key : '',
        propertyAddress: val.propertyAddress ? val.propertyAddress : '',
        propertyCity: val.propertyCity ? val.propertyCity : '',
        propertyPostCode: val.propertyPostCode ? val.propertyPostCode : '',
        propertyCountry: val.propertyCountry ? val.propertyCountry : '',
        ownerFirstName: val.ownerFirstName ? val.ownerFirstName : '',
        ownerLastName: val.ownerLastName ? val.ownerLastName : '',
        rooms: data.rooms ? data.rooms : val.rooms,
        author: data.author ? data.author : `${user.firstName + ' ' + user.lastName}`,
        date: data.date ? this.datePDFReport(data.date) : this.datePDFReport(new Date())
      })

      this.props.popup({
        popupType: 'reports',
        visible: true,
        data: newState
      });
    })
  }

  onSortChange(e) {
    e.persist()
    if (this.state.property[this.state.item]) {
      const order = this.state.order
      const item = this.state.item
      const sorted = Object.values(this.state.property[item]).sort(function(a, b) {
        return a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0);
      })
      const sortedDate = Object.values(this.state.property[item]).sort(function(a, b) {
          return new Date(a.date) > new Date(b.date) ? 1 : (new Date(a.date) < new Date(b.date) ? -1 : 0);
      })

      if(order === 'date') {
        this.setState((prevState) => ({
          ...prevState,
            property: {
              ...prevState.property,
              [item]: this.state.previousOrder === order ? Object.values(this.state.property[item]).sort(function(a, b) {
                  return new Date(a.date) < new Date(b.date) ? 1 : (new Date(a.date) > new Date(b.date) ? -1 : 0)
              }) : sortedDate
            }
          }
        ))
      } else {
        this.setState((prevState) => ({
          ...prevState,
            property: {
              ...prevState.property,
              [item]: this.state.previousOrder === order ? Object.values(this.state.property[item]).sort(function(a, b) {
                return a[order] < b[order] ? 1 : (a[order] > b[order] ? -1 : 0);
              }) : sorted
            }
          }
        ))
      }
      this.state.previousOrder === order ? this.setState({previousOrder: ''}) : this.setState({previousOrder: order})
    }
  }

  componentWillReceiveProps(nextProps, popupData) {
    if (nextProps.popupData.updated) {
      this.componentDidMount()
    }
  }

  componentDidMount() {
    if (this.props.id) {
      localStorage.setItem('idProperty', this.props.id);
    }
    const ID = localStorage.getItem('idProperty');
    database.ref(`properties/${ID}`).once('value', (snapshot) => {
      const val = snapshot.val()

      const stateTasks = [];
      for (let item in val.tasks) {
        stateTasks.push({
          id: item,
          author: val.tasks[item].author,
          status: val.tasks[item].status,
          date: val.tasks[item].date,
          name: val.tasks[item].name,
          details: val.tasks[item].details
        })
      }

      const stateReports = [];
      for (let item in val.reports) {
        stateReports.push({
          id: item,
          author: val.reports[item].author,
          date: val.reports[item].date,
          photoProfile: val.reports[item].photoProfile,
          fileName: val.reports[item].fileName,
          rooms: val.reports[item].rooms
        })
      }

      this.setState({
        propertyID: ID,
        property: {
          reports: stateReports,
          tasks: stateTasks
        }
      })
    })

    //Get logged user
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
      user: user.firstName + ' ' + user.lastName,
      userStatus: user.status
      }
    ))

    if (this.props.location !== undefined) {
      if(this.props.location.pathname === '/reports') {
        this.setState({
          location: '/reports'
        });
      }
    }
  }

  render() {
    const tasks = this.state.property ? this.state.property.tasks : '';
    const reports = this.state.property ? this.state.property.reports : '';
    const { currentPageTasks, currentPageReports  } = this.state;
    return (
      <div className={ this.state.location === '/reports' ? 'wrapper reports' : '' }>
        <section className="section-3">
          <div className="container">
            <div className="box-item no-hover padding">
              <h2>Reports</h2>
              {this.state.userStatus === 'Admin' || this.state.userStatus === 'Collaborator' ? <button onClick={(event) => {const key = ''; this.onPopupReports(event,key)}} className="btn-third align-right">Add new</button> : ''}
              <div className="overflow-x-mobile">
                <table className="blanc reports">
                  <thead>
                    <tr>
                      <th><span onClick={(e) => this.setState({order: 'date', item: 'reports'}, () => {this.onSortChange(e)})} className={this.state.order === 'date' ? 'order checked' : 'order'}>Date</span></th>
                      <th><span onClick={(e) => this.setState({order: 'author', item: 'reports'}, () => {this.onSortChange(e)})} className={this.state.order === 'author' ? 'order checked' : 'order'}>Author</span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { reports ? Object.keys(reports).slice(currentPageReports * this.pageSize, (currentPageReports + 1) * this.pageSize).map((key, item, i) => {
                        const dateToFormat = reports[key].date;
                        const data = reports[key];
                        const id = reports[key].id
                        return (
                          <tr key={`${i} ${key}`}>
                            <td><span>{this.date(dateToFormat)}</span></td>
                            <td><div className="image-profile" style={{backgroundImage: "url("+reports[key].photoProfile+")" }}/><p>{reports[key].author}</p></td>
                            <td>
                              <ul className="buttons">
                                <li onClick={(event) => this.setState({edit: false}, () => {this.onPopupReports(data, id)})} className="view"/>
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.setState({edit: true}, () => {this.onPopupReports(data, id)})} className="edit"/> : ''}
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.setState({type: 'reports'}, () => {this.onDelete(event, id)})} className="delete"/> : ''}
                              </ul>
                            </td>
                          </tr>
                        )
                      }
                    ) : ''}
                  </tbody>
                </table>
              </div>
              { reports ?
                <div>
                  <button
                    className={`${"btn-prev cararra"} ${currentPageReports > 0 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'reports'}, () => {this.handleNavigation(e, currentPageReports -1)})}
                    previous
                  />
                  <button
                    className={`${"btn-next cararra"} ${currentPageReports < Math.ceil(Object.keys(this.state.property.reports).length / this.pageSize) - 1 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'reports'}, () => {this.handleNavigation(e, currentPageReports +1)})}
                    next
                  />
              </div> : ''}
            </div>
          </div>
        </section>

        <section className="section-4">
          <div className="container">
            <div className="box-item no-hover padding">
              <h2>Tasks</h2>
              {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopupTasks(event)} className="btn-third align-right">Add new</button> : ''}
              <div className="overflow-x-mobile">
                <table className="blanc tasks">
                  <thead>
                    <tr>
                      <th><span onClick={(e) => this.setState({order: 'name', item: 'tasks'}, () => {this.onSortChange(e)})} className={this.state.order === 'name' ? 'order checked' : 'order'}>Name</span></th>
                      <th><span onClick={(e) => this.setState({order: 'date', item: 'tasks'}, () => {this.onSortChange(e)})} className={this.state.order === 'date' ? 'order checked' : 'order'}>Date</span></th>
                      <th><span onClick={(e) => this.setState({order: 'status', item: 'tasks'}, () => {this.onSortChange(e)})} className={this.state.order === 'status' ? 'order checked' : 'order'}>Status</span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    { tasks ? Object.keys(tasks).slice(currentPageTasks * this.pageSize, (currentPageTasks + 1) * this.pageSize).map((key, item, i) => {
                        const dateToFormat = tasks[key].date;
                        const data = tasks[key]
                        const id = tasks[key].id
                        return (
                          <tr key={`${i} ${key}`}>
                            <td><span>{tasks[key].name}</span></td>
                            <td><span className="slate-gray">{this.date(dateToFormat)}</span></td>
                            <td><span className={`fc-event ${tasks[key].status}`}>{tasks[key].status}</span></td>
                            <td>
                              <ul className="buttons">
                                <li onClick={(event) => this.onPopupTasks(data, id)} className="view"/>
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onPopupTasks(data, id)} className="edit"/> : ''}
                                {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.setState({type: 'tasks'}, () => {this.onDelete(event, id)})} className="delete"/> : ''}
                              </ul>
                            </td>
                          </tr>
                        )
                      }
                    ) : ''}
                  </tbody>
                </table>
              </div>
              { tasks ?
                <div>
                  <button
                    className={`${"btn-prev cararra"} ${currentPageTasks > 0 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'tasks'}, () => {this.handleNavigation(e, currentPageTasks -1)})}
                    previous
                  />
                  <button
                    className={`${"btn-next cararra"} ${currentPageTasks < Math.ceil(Object.keys(this.state.property.tasks).length / this.pageSize) - 1 ? ' checked' : ''}`}
                    onClick={(e) => this.setState({item: 'tasks'}, () => {this.handleNavigation(e, currentPageTasks +1)})}
                    next
                  />
              </div> : ''}
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(LuxusReports);
