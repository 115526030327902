import React from 'react';
import { Link } from 'react-router-dom';
import database from '../firebase/firebase';
import { connect } from 'react-redux';
import { property } from '../actions/property';
import { signup } from '../actions/signup';

export class Accueil extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      hover: false,
      properties: [],
      saveProperties: [],
      currentPage: 1,
      todosPerPage: 9,
      search: ''
    }
    this.handleClick = this.handleClick.bind(this);
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    })
  }

  handleSearch (e) {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      search: value
    });

    function filterByValue(array, string) {
    return array.filter(o =>
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
    }

    this.setState({
      properties: filterByValue(this.state.saveProperties, value)
    })
  }

  componentWillMount(){
    if(document.location.href !== '/dashboard') {
      window.history.pushState('', '', '/dashboard');
    }

    this.props.property('')
    localStorage.setItem('propertyID', '')

    if (this.props.user) {
      localStorage.setItem('user', JSON.stringify(this.props.user));
    }
    const user = JSON.parse(localStorage.getItem('user'));
    if(user.status === 'Admin' || user.status === 'Collaborator') {
      database.ref('properties').once('value', (snapshot) => {
        const val = snapshot.val()
        const newState = [];
        for (let item in val) {
          newState.push({
            id: item,
            photo: val[item].photo ? val[item].photo : '',
            propertyAddress: val[item].propertyAddress ? val[item].propertyAddress : '',
            propertyCity: val[item].propertyCity ? val[item].propertyCity : '',
            propertyPostCode: val[item].propertyPostCode ? val[item].propertyPostCode : '',
            propertyCountry: val[item].propertyCountry ? val[item].propertyCountry : '',
            ownerFirstName: val[item].ownerFirstName ? val[item].ownerFirstName : '',
            ownerLastName: val[item].ownerLastName ? val[item].ownerLastName : ''
          });
        }
        this.setState({
          properties: newState,
          saveProperties: newState
        });
      })
    } else if (user.status === 'Client' || user.status === 'Main contact') {
      const newState = []
      const user = JSON.parse(localStorage.getItem('user'));
      database.ref('properties').orderByChild('mainContactEmail').equalTo(user.email).once('value', (snapshot) => {
        if (snapshot.exists()){
          const val = snapshot.val()
          for (let item in val) {
            newState.push({
              id: item,
              photo: val[item].photo ? val[item].photo : '',
              propertyAddress: val[item].propertyAddress ? val[item].propertyAddress : '',
              propertyCity: val[item].propertyCity ? val[item].propertyCity : '',
              propertyPostCode: val[item].propertyPostCode ? val[item].propertyPostCode : '',
              propertyCountry: val[item].propertyCountry ? val[item].propertyCountry : '',
              ownerFirstName: val[item].ownerFirstName ? val[item].ownerFirstName : '',
              ownerLastName: val[item].ownerLastName ? val[item].ownerLastName : ''
            });
          }
          this.setState({
            properties: newState,
            saveProperties: newState
          })
        }
      }).then(
        database.ref('properties').orderByChild('ownerEmail').equalTo(user.email).once('value', (snapshot) => {
          const val = snapshot.val()
          if (snapshot.exists()){
            for (let item in val) {
              newState.push({
                id: item,
                photo: val[item].photo ? val[item].photo : '',
                propertyAddress: val[item].propertyAddress ? val[item].propertyAddress : '',
                propertyCity: val[item].propertyCity ? val[item].propertyCity : '',
                propertyPostCode: val[item].propertyPostCode ? val[item].propertyPostCode : '',
                propertyCountry: val[item].propertyCountry ? val[item].propertyCountry : '',
                ownerFirstName: val[item].ownerFirstName ? val[item].ownerFirstName : '',
                ownerLastName: val[item].ownerLastName ? val[item].ownerLastName : ''
              });
              this.setState({
                properties: newState,
                saveProperties: newState
              })
            }
          }
        })
      )
    }
  }

  render() {
    const { properties, currentPage, todosPerPage } = this.state;
    // Logic for pagination
    const indexOfLastTodo = currentPage * todosPerPage;
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(properties.length / todosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div className="wrapper">
          <main className="accueil">
            <section className="section-1">
              <div className="container">
                <form>
                  <input
                    type="text"
                    name="search"
                    className="search"
                    placeholder="Search a property..."
                    onChange={(e) => this.handleSearch(e)}
                    value={this.state.search}
                  />
                </form>
                <div className="row-fluid">
                  {this.state.properties.slice(indexOfFirstTodo, indexOfLastTodo).map((item, i) => {
                    return (
                      <div className="columns" key={i}>
                        <div className={this.state.hover === i ? 'box-item box-shadows' : 'box-item' }>
                          <div className="box-thumbnail" style={{backgroundImage: "url("+item.photo+")" }}/>
                          <div className="box-description">
                            <p className="address">{item.propertyAddress} <br/>{item.propertyPostCode} {item.propertyCity}, {item.propertyCountry}</p>
                            <p className="postal-code-country">{item.postCodeCountry}</p>
                            <p className="client">{item.ownerFirstName} {item.ownerLastName}</p>
                            <Link onClick={(event) => this.props.property({propertyID: this.props.location.id})} to={{pathname: '/property', id: item.id}}><button onMouseEnter={(event) => this.setState({hover: i})} onMouseLeave={(event) => this.setState({hover: false})}>Details</button></Link>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {pageNumbers.length > 1 ?
                <ul className="pagination">
                  {pageNumbers.map(number => {
                    return (
                      <li
                        key={number}
                        id={number}
                        onClick={this.handleClick}
                      >
                        {number}
                      </li>
                    );
                  })}
                </ul> : ''}
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth: {loginStatus}, signup: {user}, popup: {popupData}, property: {propertyID}}) => ({
  propertyID,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  property: (propertyID) => dispatch(property({propertyID})),
  signup: (user) => dispatch(signup({user}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Accueil);
