import React from 'react';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import { signup } from '../actions/signup';
import { property } from '../actions/property';
import database from '../firebase/firebase';

export class Identification extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      formErrors: {email: '', password: ''}
    }
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  onSubmit = (e) => {
    e.preventDefault();
    var self = this;
    const email = this.state.email;
    const password = this.state.password;
    const ID = this.state.email.replace(/[@._]/g, '');

    database.ref(`users/${ID}`).on('value', (snapshot) => {
      const val = snapshot.val();

      if (snapshot.exists()){
        if ((email === val.email) && (password === val.password)) {
          self.props.login()
          self.props.property('')
          self.props.signup({ID: ID, status: val.status, email: val.email, firstName: val.firstName, lastName: val.lastName})
          localStorage.clear()
          const authInfos = {
            'login': true,
            'firstName': val.firstName,
            'lastName': val.lastName,
            'email': val.email,
            'ID': ID,
            'status': val.status
          }
          localStorage.setItem('fixbuglocalstorage', 'fixbuglocalstorage');
          localStorage.setItem('user', JSON.stringify(authInfos));
          self.props.history.push('/dashboard');
        }
        if (email !== val.email) {
          self.setState({
            formErrors: {
              email: 'has-error'
            }
          });
        }
        if (password !== val.password) {
          self.setState({
            formErrors: {
              password: 'has-error'
            }
          });
        }
      }
    })
  }

  componentDidMount() {
    if (typeof window !== null) {
      if (!window.location.href.indexOf("https://") > -1) {
        if (sessionStorage.getItem('https') !== 'true') {
          window.location.href = 'https://pm.luxus-private.com/'
          sessionStorage.setItem('https', 'true');
        }
      }
    }
  }

  render() {
    return (
      <div>
        <div className="wrapper identification">

          <div className="wrapper-background">
            <div style={{backgroundImage: "url('/images/Paris-1.jpg')" }} />
            <div style={{backgroundImage: "url('/images/Paris-2.jpg')" }} />
            <div style={{backgroundImage: "url('/images/Paris-3.jpg')" }} />
          </div>

          <main className="identification">
            <section className="section-1">
              <div className="container identification">
                <p className="semibold">Welcome to</p>
                <img className="logo" type="image/svg+xml" src="/icons/luxus-logo.svg" alt="" onDragStart={this.preventDragHandler}/>
                <form className="identification" onSubmit={this.onSubmit}>
                  <input
                    type="text"
                    name="email"
                    className={this.state.formErrors.email}
                    value={this.state.email}
                    onChange={(event) => this.handleUserInput(event)}
                    placeholder="Email address"
                  />
                  <input
                    type="password"
                    name="password"
                    className={this.state.formErrors.password}
                    value={this.state.password}
                    onChange={(e) => this.handleUserInput(e)}
                    placeholder="Password"
                  />
                  <input type="submit" className="submit" value="Enter"/>
                </form>
                <p className="copyright text-center blanc">2020 © Luxus Property Management</p>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  signup: (user) => dispatch(signup({user})),
  property: (propertyID) => dispatch(property({propertyID})),
  login: (loginStatus) => dispatch(login())
});

export default connect(undefined, mapDispatchToProps)(Identification);
