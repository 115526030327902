import React from 'react';
import socketIOClient from 'socket.io-client'
import database from '../firebase/firebase';

export class Contact extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      subject: '',
      message: '',
      mainContactEmail: '',
      user: {},
      success: false,
      error: false
    }
  }

  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  onSubmit (e){
    e.preventDefault();
    if ((this.state.subject !== '') && (this.state.message !== '')) {
      const socket = socketIOClient('/', {transports: ['websocket']});
      socket.emit('send email', {
        email: this.state.user.email,
        subject: this.state.subject,
        message: this.state.message,
        mainContactEmail: this.state.mainContactEmail
      })
      this.setState({subject: '', message: '', success: true, error: false});
    } else {
      this.setState({error: true, succes: false});
    }
  }

  componentDidMount() {
    fetch('/devis-infos')
      .then(res => res.json())
      .then(res => console.log(res))

    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
        'user': {
          name: user.firstName + ' ' + user.lastName,
          email: user.email
        }
      }
    ))

    database.ref('properties').orderByChild('ownerEmail').equalTo(user.email).once('value', (snapshot) => {
      const val = snapshot.val()
      if (snapshot.exists()){
        this.setState({
          mainContactEmail: Object.entries(val)[0][1].mainContactEmail
        })
      }
    })

  }

  render() {
    return (
      <div>
        <div className="wrapper">
          <main className="contact">
            <section className="section-1">
              <div className="container">
                <div className="box-item no-hover padding">
                  <h2>Contact us</h2>
                  <form className="contact">
                    <label>Subject</label>
                    <input
                      type="text"
                      name="subject"
                      className="subject"
                      onChange={(event) => this.handleUserInput(event)}
                      placeholder="The subject of your message here"
                    />
                    <label>Message</label>
                    <textarea
                      name="message"
                      className="message"
                      onChange={(event) => this.handleUserInput(event)}
                      placeholder="Your message here"
                    />
                    <input onClick={(e) => this.onSubmit(e)} type="submit" className="submit" value="Send"/>
                    <p className={this.state.success === true ? 'success-email open' : 'success-email'}>Your email was sent successfully</p>
                    <p className={this.state.error === true ? 'error-email open' : 'error-email'}>Error: fill all the fields please</p>
                  </form>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    );
  }
}

export default Contact;
