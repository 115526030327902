import React from 'react'
import { connect } from 'react-redux'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom'
import Aside from '../components/Aside'
import Identification from '../components/Identification'
import Accueil from '../components/Accueil'
import SensorsInformation from '../components/SensorsInformation'
import Documents from '../components/Documents'
import Property from '../components/Property'
import AddProperty from '../components/AddProperty'
import Settings from '../components/Settings'
import About from '../components/About'
import AdditionalServices from '../components/AdditionalServices'
import Agenda from '../components/Agenda'
import Contact from '../components/Contact'
import LuxusReports from '../components/LuxusReports'

export class AppRouter extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      login: false,
      user: null
    }
  }

  componentWillReceiveProps (nextProps) {
    const { user, loginStatus } = nextProps;
    if(user) {
      this.setState({
        login: true,
        user: {
          status: user.status
        }
      });
    }
  }

  componentWillMount() {
    if (localStorage.getItem('user') && (JSON.parse(localStorage.getItem('user')).login !== false) ) {
      const user = JSON.parse(localStorage.getItem('user'));
      this.setState({
        login: true,
        user: {
          status: user.status
        }
      });
    }
  }

  render() {
    const userRoutes = [
      {
        path: '/',
        component: Identification,
      },
      {
        path: '/dashboard',
        component: Accueil,
        data: JSON.parse(localStorage.getItem('user'))
      },
      {
        path: '/property',
        component: Property
      },
      {
        path: '/add-property',
        component: AddProperty
      },
      {
        path: '/sensors-information',
        component: SensorsInformation
      },
      {
        path: '/settings',
        component: Settings
      },
      {
        path: '/about',
        component: About
      },
      {
        path: '/additional-services',
        component: AdditionalServices
      },
      {
        path: '/agenda',
        component: Agenda
      },
      {
        path: '/documents',
        component: Documents
      },
      {
        path: '/reports',
        component: LuxusReports
      },
      {
        path: '/contact',
        component: Contact
      }
    ]

    return (
      <BrowserRouter>
        {this.state.login === true ? <Aside data={this.state.user.status}/> : ''}
        <Route
          exact
          render={() => (
            this.state.login === true && this.state.user.status === 'Admin' ? (
              <Switch>
                {userRoutes.filter((item) => {
                  return item.path !== '/' && item.path !== '/documents' && item.path !== '/reports' &&
                  item.path !== '/tasks' && item.path !== '/agenda' && item.path !== '/sensors-information' &&
                  item.path !== '/about' && item.path !== '/contact' }
                ).map(route => (
                  <Route key={route.path} exact path={route.path} component={route.component} />
                ))}
                <Route path="*" exact component={Accueil} />
              </Switch>

            ) : this.state.login === true && this.state.user.status === 'Collaborator' ? (
              <Switch>
                {userRoutes.filter((item) => {
                  return item.path !== '/' && item.path !== '/documents' && item.path !== '/add-property'
                  && item.path !== '/reports' && item.path !== '/agenda' && item.path !== '/sensors-information' && item.path !== '/tasks'
                  && item.path !== '/about' && item.path !== '/settings' && item.path !== '/contact' }
                ).map(route => (
                  <Route key={route.path} exact path={route.path} component={route.component} />
                ))}
                <Route path="*" exact component={Accueil} />
              </Switch>
            ) : this.state.login === false ? (
              <Switch>
                <Route key="/" exact path="/" component={Identification} />
                <Route path="*" exact component={Identification} />
              </Switch>
            ) : (
              <Switch>
                {userRoutes.filter((item) => {
                  return item.path !== '/' && item.path !== '/dashboard' && item.path !== '/add-property' && item.path !== '/settings' }
                ).map(route => (
                  <Route key={route.path} exact path={route.path} component={route.component} />
                ))}
                <Route path="*" exact component={Accueil} />
              </Switch>
            )
          )}
        />
      </BrowserRouter>
    )
  }
}

const mapStateToProps = ({auth: {loginStatus}, signup: {user}}) => ({
  loginStatus,
  user
})

export default connect(mapStateToProps)(AppRouter)
