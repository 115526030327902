import React from 'react'
import database from '../../firebase/firebase'
import { popup } from '../../actions/popup'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import printPDF from './ExportPDF.js'
import uuidv1 from 'uuid/v1'
import 'react-datepicker/dist/react-datepicker.css'

export class Report extends React.Component {
  constructor(props) {
    const newID = uuidv1();
    super(props)
    this.state = {
      newReportID: newID,
      edit: this.props.data.edit === true ? true : false,
      boxButtons: this.props.data.edit === true ? true : false,
      data: this.props.data,
      date: '',
      rooms: [],
      room: '',
      newElementRoom: [],
      photoProfile: '',
      item: '',
      grade: ''
    }
  }

  handleEdit() {
    this.setState({
      edit: true,
      boxButtons: true
    });
  }

  handleNewItemRoom(e,roomNumber) {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      'newElementRoom': {
        [roomNumber]: value
      }
    })
  }

  addRoomElement(roomRadioBtn,roomNumber) {
    const date = new Date(this.state.date)
    date.setDate(date.getDate() + 1)
    if (this.state.newElementRoom !== '') {
      database.ref('users').orderByChild('email').equalTo(this.state.userEmail).once('value', (snapshot) => {
        if (snapshot.exists()){
          snapshot.forEach(ss => {
            this.setState({
              photoProfile: ss.child('photoProfile').val()
            })
          })
        }
      }).then(value => {
        database.ref(`properties/${this.state.data.propertyID}/reports/${this.state.data.reportID ? this.state.data.reportID : this.state.newReportID}`).update({
          date: date,
          author: this.state.data.author ? this.state.data.author : this.state.user,
          photoProfile: this.state.photoProfile,
          'rooms': {
            [roomNumber]: {
              [roomRadioBtn]: {
                [this.state.newElementRoom[roomRadioBtn]]: ''
              }
            }
          }
        })
      })
      database.ref(`properties/${this.state.data.propertyID}/rooms/${roomNumber}/${roomRadioBtn}`).update({
        [this.state.newElementRoom[roomRadioBtn]]: ''
      })
    }
    this.setState((prevState) => ({
      ...prevState,
        rooms: {
          ...prevState.rooms,
          [roomNumber]: {
            ...prevState.rooms[roomNumber],
            [roomRadioBtn]: {
              ...prevState.rooms[roomNumber][roomRadioBtn],
              [this.state.newElementRoom[roomRadioBtn]]: ''
            }
          }
        }
      }
    ))
  }

  removeRoomElement(roomRadioBtn,itemRoom,roomNumber) {
    const refPropertyRoom = database.ref(`properties/${this.state.data.propertyID}/rooms/${itemRoom}/${roomRadioBtn}/${roomNumber}`);
    const refReportRoom = database.ref(`properties/${this.state.data.propertyID}/reports/${this.state.data.reportID ? this.state.data.reportID : this.state.newReportID}/rooms/${itemRoom}/${roomRadioBtn}/${roomNumber}`);
    refReportRoom.remove()
    refPropertyRoom.remove()

    var rooms = this.state.rooms[itemRoom][roomRadioBtn]
    delete rooms[roomNumber];
    this.setState((prevState) => ({
      ...prevState,
        rooms: {
          ...prevState.rooms
          // [roomNumber]: {
          //   ...prevState.rooms[roomNumber],
          //   [roomRadioBtn]: {
          //     ...rooms
          //   }
          // }
        }
      }
    ))
  }


  handleUserInput (e,room,roomNumber) {
    e.preventDefault();
    const value = e.target.value;
    this.setState((prevState) => ({
      ...prevState,
        rooms: {
          ...prevState.rooms,
          [room]: {
            ...prevState.rooms[room],
            [roomNumber]: {
              ...prevState.rooms[room][roomNumber],
              Note: value
            }
          }
        }
      }
    ))
    this.handleRoomsSave.click()
  }

  handleRadioBtn (e, roomRadioBtn, roomNumber, itemRoom, grade) {
    e.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
        rooms: {
          ...prevState.rooms,
          [roomRadioBtn]: {
            ...prevState.rooms[roomRadioBtn],
            [roomNumber]: {
              ...prevState.rooms[roomRadioBtn][roomNumber],
              [itemRoom]: grade
            }
          }
        }
      }
    ))
    this.handleRoomsSave.click()
  }

  closePopup(e) {
    e.preventDefault()
    var self = this
    self.props.popup({popupType: '', visible: false, data: ''});
  }

  handleChange = date => {
    this.setState({
      date: date
    })
  };

  saveRadioBtns() {
    const date = new Date(this.state.date);
    date.setDate(date.getDate() + 1);
    database.ref('users').orderByChild('email').equalTo(this.state.userEmail).once('value', (snapshot) => {
      if (snapshot.exists()){
        snapshot.forEach(ss => {
          this.setState({
            photoProfile: ss.child('photoProfile').val()
          })
        })
      }
    }).then(value => {
      database.ref(`properties/${this.state.data.propertyID}/reports/${this.state.data.reportID ? this.state.data.reportID : this.state.newReportID}`).update({
        date: date,
        author: this.state.data.author ? this.state.data.author : this.state.user,
        photoProfile: this.state.photoProfile,
        rooms: this.state.rooms
      })
    })
  }

  saveReport() {
    const date = new Date(this.state.date);
    database.ref('users').orderByChild('email').equalTo(this.state.userEmail).once('value', (snapshot) => {
      if (snapshot.exists()){
        snapshot.forEach(ss => {
          this.setState({
            photoProfile: ss.child('photoProfile').val()
          })
        })
      }
    }).then(value => {
      database.ref(`properties/${this.state.data.propertyID}/reports/${this.state.data.reportID ? this.state.data.reportID : this.state.newReportID}`).update({
        date: date,
        author: this.state.data.author ? this.state.data.author : this.state.user,
        photoProfile: this.state.photoProfile,
        rooms: this.state.rooms
      })
    }).then(value => {
      this.props.popup({
        visible: false,
        updated: true
      })
    })
  }

  downloadPDF() {
    const state = this.state;
    printPDF(state);
  }

  componentWillMount() {
    this.setState({
      rooms: this.props.data.rooms
    })
  }

  componentDidMount() {
    const date = new Date();
    date.setDate(date.getDate());
    const today = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear()
    if (this.state.data.reportID === '') {
      this.setState({
        date: new Date(this.state.data.date)
      })
    }
    if ((this.state.data.date === today) || (this.state.data.reportID === '')) {
      this.setState({
        edit: true,
        boxButtons: true
      })
    } else {
      const dateObject = new Date(this.state.data.date);
      const date = new Date(dateObject.toString());
      date.setDate(date.getDate());
      const mois = [
        "Jan", "Feb", "March",
        "Avr", "May", "June", "Jul",
        "August", "Sept", "Oct",
        "Nov", "Dec"
      ];
      const dateNoEdit = date.getDate() + ' ' + mois[date.getMonth()] + ' ' + date.getFullYear()

      this.setState({
        date: new Date(this.state.data.date),
        dateNoEdit: dateNoEdit
      })
    }
    //Get user email
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
        user: user.firstName + ' ' + user.lastName,
        userEmail: user.email,
        userStatus: user.status
      }
    ))
  }

  render() {
    const listOrder = [
      'Entrance',
      'Living room',
      'Dining room',
      'Kitchen',
      'Bedroom',
      'Bathroom',
      'Dressing',
      'WC & wash basin',
      'Staff room',
      'Corridor',
      'Cellar',
      'Garage',
      'Gym',
      'Swimming Pool',
      'Balcony',
      'Terrace',
      'Carridor',
      'Swimming pool',
      'Walls, floor & carpet',
      'Window(s) & window shutters'
    ];

    return (
      <section>
        {this.state.boxButtons === true ?
        <div className="box-save-cancel">
          <button onClick={(event) => this.closePopup(event)}/>
          <button onClick={(event) => this.saveReport()}/>
        </div> : ''}
        <div className={`box-buttons ${this.state.userStatus === 'Client' ? 'client' : this.state.userStatus === 'Main contact' ? 'main-contact' : ''}`}>
          <button onClick={(event) => this.downloadPDF()} className="btn-seventh align-right">PDF</button>
          {this.state.boxButtons === false ? <button onClick={(e) => this.handleEdit(e)} className="align-right btn-edit"/> : ''}
        </div>
        <img className="logo" type="image/svg+xml" src="/icons/luxus-logo.svg" alt="" onDragStart={this.preventDragHandler}/>
          {this.state.boxButtons === true ?
          <div className="box-btns-save-mobile">
            <button onClick={(event) => this.closePopup(event)} className="btn-sixth">Save</button>
            <button onClick={(event) => this.saveReport()} className="btn-secondary">Cancel</button>
          </div> : ''}
        <input onClick={(event) => this.saveRadioBtns()} ref={(ref) => this.handleRoomsSave = ref} hidden/>
          <table className="blanc pdf-report">
            <thead>
              <tr>
                <th class="headline" colspan="3"><span>Inspection report</span></th>
              </tr>
            </thead>
            <tbody className={this.state.edit === true ? 'edit' : ''}>
              <tr>
                <td>
                  <ul>
                    <li>Apartment :</li>
                    <li>{this.state.data.propertyAddress} {this.state.data.propertyCity} {this.state.data.propertyPostCode}, {this.state.data.propertyCountry}</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Owner :</li>
                    <li>{this.state.data.ownerFirstName} {this.state.data.ownerLastName}</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td>
                  <ul>
                    <li>Date :</li>
                    <li>
                      {this.state.edit === true ?
                      <DatePicker
                        dateFormat="d'/'M'/'yyyy"
                        selected={this.state.date}
                        onChange={this.handleChange}
                      /> :
                      <input
                        type="text"
                        name="date"
                        className="date"
                        placeholder={this.state.dateNoEdit}
                      />}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Inspector :</li>
                    <li>{this.state.data.author}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <table className="blanc pdf-report-nomenclature">
            <thead>
              <tr>
                <th colspan="3"><span>Nomenclature</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ul>
                    <li><span>GC</span> Good condition</li>
                    <li><span>MC</span> Medium condition</li>
                    <li><span>PC</span> Poor condition</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li><span>OOO</span> Out of order</li>
                    <li><span>N/A</span> Non applicable</li>
                    <li><span>TBR</span> To be reviewed</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

            {Object.keys(this.state.rooms).sort((a, b) => listOrder.indexOf(a) - listOrder.indexOf(b)).map((key, item, i) => {
            const rooms = this.state.rooms[key];
            if(rooms.quantity !== 0) {
              return Object.entries(rooms).sort((a, b) => a > b).map((item) => {
                const roomRadioBtn = key
                const roomNumber = item[0]

                if (item[0] !== 'Quantity') {
                  if (item[0] !== 'items') {
                    return (
                      <table className="blanc pdf-report room-report">
                        <thead>
                          <tr>
                            <th scope="row" colspan="7"><span>{item[0]}</span></th>
                          </tr>
                        </thead>
                        <tbody className={this.state.edit === true ? 'edit' : ''}>
                          <tr>
                            <td></td>
                            <td><span>GC </span></td>
                            <td><span>MC </span></td>
                            <td><span>PC </span></td>
                            <td><span>N/A</span></td>
                            <td><span>OOO</span></td>
                            <td><span>TBR</span></td>
                          </tr>

                          {Object.entries(item[1]).sort().map((key) => {
                            const itemRoom = key[0]
                            const room = item
                            if(key[0] !== 'Note') {
                              return (
                                <tr>
                                  <td><span>{key[0]}</span>{this.state.edit === true ? <div onClick={(event) => this.removeRoomElement(roomNumber,roomRadioBtn,itemRoom)} className="btn-remove"/> : ''}</td>
                                  <td colspan="6">
                                    <label className="label">
                                      <input type="radio" value="GC" onChange={(event) => {const grade = 'GC'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'GC' ? 'GC' : ''}/><span className="checkmark"/>
                                    </label>
                                    <label className="label">
                                      <input type="radio" value="MC" onChange={(event) => {const grade = 'MC'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'MC' ? 'MC' : ''}/><span className="checkmark"/>
                                    </label>
                                    <label className="label">
                                      <input type="radio" value="PC" onChange={(event) => {const grade = 'PC'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'PC' ? 'PC' : ''}/><span className="checkmark"/>
                                    </label>
                                    <label className="label">
                                      <input type="radio" value="N/A" onChange={(event) => {const grade = 'N/A'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'N/A' ? 'N/A' : ''}/><span className="checkmark"/>
                                    </label>
                                    <label className="label">
                                      <input type="radio" value="000" onChange={(event) => {const grade = 'OOO'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'OOO' ? 'OOO' : ''}/><span className="checkmark"/>
                                    </label>
                                    <label className="label">
                                      <input type="radio" value="TBR" onChange={(event) => {const grade = 'TBR'; this.handleRadioBtn(event, roomRadioBtn, roomNumber, itemRoom, grade)}} checked={this.state.rooms[roomRadioBtn][roomNumber][itemRoom] === 'TBR' ? 'TBR' : ''}/><span className="checkmark"/>
                                    </label>
                                  </td>
                                </tr>
                              )
                            }
                          })}

                          {this.state.edit === true ?
                          <tr scope="row" colspan="7">
                            <td scope="row" colspan="7"><input type="text" className="new-element" value={this.state.newElementRoom[roomNumber]} onChange={(event) => this.handleNewItemRoom(event,roomNumber)}/><button onClick={(event) => this.addRoomElement(roomNumber,roomRadioBtn)} className="btn-secondary btn-add-element-room">Add item</button></td>
                          </tr> : ''}

                          {Object.entries(item[1]).map((item) => {
                            const room = [key][0];
                            if(item[0] === 'Note') {
                              return (
                                <tr>
                                  <td colspan="7">
                                    <span>Note / remarks:</span>
                                    <textarea
                                      name={key[0]}
                                      className="message"
                                      onChange={(event) => this.handleUserInput(event, room, roomNumber)}
                                      value={this.state.rooms[room][roomNumber].Note}
                                    />
                                  </td>
                                </tr>
                              )
                            }
                          })}
                        </tbody>
                      </table>
                    )
                  }
                }
              });
            }
          })}
        <div className="footer"><span>Luxus Property Management — Inspection Report</span> <span></span></div>
      </section>
    );
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
});

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
