import React from 'react'
import database from '../firebase/firebase'
import { connect } from 'react-redux'
import { date, time } from '../functions/Date.js'
import { popup } from '../actions/popup'

export class Settings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sensors:[],
      propertyID: this.props.id,
      currentSensors: 0
    }
    this.pageSize = 8
  }

  preventDragHandler = (e) => {
    e.preventDefault()
  }

  onPopup(data, key){
    if (key) {
      this.props.popup({
        propertyID: this.props.id,
        ID: key,
        popupType: 'sensors',
        visible: true,
        data: data
      })
    } else {
      this.props.popup({
        propertyID: this.props.id,
        popupType: 'sensors',
        visible: true,
        data: {
          status: '',
          name: '',
          date: '',
          details: ''
        }
      })
    }
  }

  onDelete(e, id) {
    e.preventDefault()
    this.props.popup({
      propertyID: this.props.id,
      popupType: 'delete',
      field: 'sensors',
      visible: true,
      data: id
    })
  }

  handleNavigation(e, index) {
    e.persist()
    const pagesCountSensors = Math.ceil(Object.keys(this.state.sensors).length / this.pageSize);
    this.setState({
      currentSensors: index < 0 ? 0 : index >= pagesCountSensors ? (index - 1) : index
    })
  }

  onSortChange(e) {
    e.persist()
    const order = this.state.order
    const previousOrder = this.state.previousOrder
    const sorted = Object.values(this.state.sensors).sort((a,b) => {
        return a[order] > b[order] ? 1 : (a[order] < b[order] ? -1 : 0)
    })
    const sortedDate = Object.values(this.state.sensors).sort((a,b) => {
        return new Date(a.date) > new Date(b.date) ? 1 : (new Date(a.date) < new Date(b.date) ? -1 : 0)
    })

    if(order === 'date') {
      this.setState({
        sensors: previousOrder === order ? sortedDate.reverse() : sortedDate
      })
    } else {
      this.setState({
        sensors: previousOrder === order ? sorted.reverse() : sorted
      })
    }
    previousOrder === order ? this.setState({previousOrder: ''}) : this.setState({previousOrder: order})
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.popupData.updated === true) {
      this.componentDidMount()
    }
  }

  componentDidMount(){
    if (this.props.id) {
      localStorage.setItem('idProperty', this.props.id);
    }
    const ID = localStorage.getItem('idProperty');
    database.ref(`properties/${ID}/sensors`).once('value', (snapshot) => {
      const val = snapshot.val()
      if (snapshot.exists()) {
        this.setState({
          sensors: val
        })
      }
    })

    //Get logged user
    const user = JSON.parse(localStorage.getItem('user'));
    this.setState((prevState) => ({
      ...prevState,
      user: user.firstName + ' ' + user.lastName,
      userStatus: user.status
      }
    ))

    if (this.props.location !== undefined) {
      if(this.props.location.pathname === '/sensors-information') {
        this.setState({
          location: '/sensors-information'
        })
      }
    }
  }

  render() {
    const sensors = this.state.sensors ? this.state.sensors : ''
    const { currentSensors  } = this.state

    return (
      <div>
        <div className={ this.state.location === '/sensors-information' ? 'wrapper' : '' }>
          <section className="section-2">
            <div className="container">
              <div className="box-item no-hover padding">
                <h2>Sensors information</h2>
                {this.state.userStatus === 'Admin' ? <button onClick={(event) => this.onPopup(event)} className="btn-third Sensors align-right">Add new</button> : ''}
                <div className="overflow-x-mobile">
                  <table className="blanc sensors">
                    <thead>
                      <tr>
                        <th><span onClick={(e) => this.setState({order: 'date'}, () => {this.onSortChange(e)})} className={this.state.order === 'date' ? 'order checked' : 'order'}>Date</span></th>
                        <th><span>Time</span></th>
                        <th><span onClick={(e) => this.setState({order: 'name'}, () => {this.onSortChange(e)})} className={this.state.order === 'name' ? 'order checked' : 'order'}>Sensor name</span></th>
                        <th><span onClick={(e) => this.setState({order: 'status'}, () => {this.onSortChange(e)})} className={this.state.order === 'status' ? 'order checked' : 'order'}>Status</span></th>
                        <th><span>Details</span></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                      {Object.keys(this.state.sensors).slice(currentSensors * this.pageSize, (currentSensors + 1) * this.pageSize).map((key) => {
                        const data = this.state.sensors[key]
                        const dateToFormat = data.date
                        const id = data.id
                        const details = data.details

                          return (
                            <tr key={`${data.date} + ${data.name}`}>
                              <td><span>{date(dateToFormat)}</span></td>
                              <td><span>{time(dateToFormat)}</span></td>
                              <td><span>{data.name}</span></td>
                              <td><span>{data.status}</span></td>
                              <td><span>{details.substring(0,50)} {data.details ? '...' : ''}</span></td>
                              <td>
                                <ul className="buttons">
                                  <li onClick={(event) => this.onPopup(data,id)} className="view"/>
                                  {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.onPopup(data, id)} className="edit"/> : ''}
                                  {this.state.userStatus === 'Admin' ? <li onClick={(event) => this.setState({type: 'tasks'}, () => {this.onDelete(event, id)})} className="delete"/> : ''}
                                </ul>
                              </td>
                            </tr>
                          )
                        }
                      )}

                    </tbody>
                  </table>
                </div>
                { sensors ?
                  <div>
                    <button
                      className={`${"btn-prev cararra"} ${currentSensors > 0 ? ' checked' : ''}`}
                      onClick={(e) => this.handleNavigation(e, currentSensors - 1)}
                      previous
                    />
                    <button
                      className={`${"btn-next cararra"} ${currentSensors < Math.ceil(Object.keys(this.state.sensors).length / this.pageSize) - 1 ? ' checked' : ''}`}
                      onClick={(e) => this.handleNavigation(e, currentSensors + 1)}
                      next
                    />
                </div> : ''}
              </div>
            </div>
          </section>

        </div>
      </div>
    )
  }
}

const mapStateToProps = ({popup: {popupData}}) => ({
  popupData
})

const mapDispatchToProps = (dispatch) => ({
  popup: (popupData) => dispatch(popup({popupData}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
