import React, { useState, useEffect } from 'react'
import database from '../firebase/firebase'

function AdditionalServices(props) {
  const [additionalServices, setAdditionalServices] = useState([])
  const [additionalServicesPhoto, setAdditionalServicesPhoto] = useState({})

  useEffect(() => {
    database.ref('additional-services').once('value', (snapshot) => {
      const val = snapshot.val()
      if (snapshot.exists()) {
        if (val) {
          setAdditionalServices(val)
        }
        if (snapshot.child('photo').val()) {
          setAdditionalServicesPhoto(snapshot.child('photo').val())
        }
      }
    })

  }, [])

  return (
    <div>
      <div className="wrapper">
        <main className="about">
          <section className="section-1">
            <div className="container">
              <div className="row-fluid">
                <div className="large-6 columns">
                  <div className="big-about-photo" style={{backgroundImage: "url("+additionalServicesPhoto+")" }}/>
                </div>
                <div className="large-6 columns">
                  
                  {additionalServices.title ?
                  <div className="box-item no-hover padding">
                    <form>
                      <h2>{additionalServices.title}</h2>
                      <p className="desc-about">{additionalServices.text}</p>
                      {additionalServices.fileURL ? <a className="btn btn-sixth" href={additionalServices.fileURL} rel="noopener noreferrer" target="_blank">Download our PDF</a> : ''}
                    </form>
                  </div> : ''}

                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  )
}

export default AdditionalServices
