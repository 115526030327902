import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { login, logout } from '../actions/auth'
import { popup } from '../actions/popup'
import { signup } from '../actions/signup'
import { property } from '../actions/property'
import Popup from './Popup'

export class Aside extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location: '',
      user: '',
      btnMenuMobile: false,
      propertyID: '',
      popup: {
        visible: false,
        popupType: '',
        data: ''
      }
    }
  }

  onLogout = (e) => {
    const user = {
      'login': false,
      'ID': '',
      'status': ''
    }
    localStorage.setItem('user', JSON.stringify(user));
    if (typeof window !== null) {
      window.location.href = '/'
    }
  }

  preventDragHandler = (e) => {
    e.preventDefault();
  }

  componentWillMount() {
    if (typeof window !== undefined) {
      this.setState({
        propertyID: localStorage.getItem('propertyID'),
        user: JSON.parse(localStorage.getItem('user')),
        location: window.location.pathname
      })
    }
    this.props.popup({
      popupData: {
        updated: false
      }
    })

    if (typeof window !== null) {
      if (!window.location.href.indexOf("https://") > -1) {
        if (sessionStorage.getItem('https') !== 'true') {
          window.location.href = 'https://pm.luxus-private.com/'
          sessionStorage.setItem('https', 'true');
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { popupData, propertyID, user, loginStatus } = nextProps;
    if(propertyID !== '') {
      localStorage.setItem('propertyID', propertyID)
      this.setState({
        propertyID: propertyID
      })
    } else {
      this.setState({propertyID: ''})
    }
    if(user) {
      this.setState({
        login: true,
        user: {
          status: user.status
        }
      })
    }
    if(popupData.visible === true) {
      this.setState({
        popup: {
          visible: nextProps.popupData.visible,
          popupType: nextProps.popupData.popupType,
          data: nextProps.popupData.data
        }
      })
    } else if (popupData.visible === false) {
      this.setState({
        popup: {
          visible: false,
          popupType: '',
          data: ''
        }
      })
    }
  }

  render() {
    return (
      <div onDragStart={this.preventDragHandler}>
        {this.state.popup.visible === true ? <Popup popup={this.state.popup.visible} popupType={this.state.popup.popupType} data={this.state.popup.data}/> : ''}
      <aside>
        <Link to='/dashboard'><img className="logo" type="image/svg+xml" src="/icons/luxus-logo.svg" alt="" onDragStart={this.preventDragHandler}/></Link>
        <div id="btn-menu-mobile"
          className={this.state.btnMenuMobile === true ? 'open' : ''}
          onClick={(event) => this.setState({
            btnMenuMobile: !this.state.btnMenuMobile
          })}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <nav role="navigation"
          onClick={(event) => this.setState({
            btnMenuMobile: !this.state.btnMenuMobile
          })}
          >
          <ul className={this.state.btnMenuMobile === true ? 'menu open' : 'menu'}>
            {this.props.data === 'Admin' ?
              <div>
                <li className={`listed-property`}><NavLink activeClassName="is-active" to="/dashboard" exact={true}>Listed Properties</NavLink></li>
                <li className={`property`}><NavLink activeClassName="is-active" to="/add-property">Add a property</NavLink></li>
                <li className={`settings`}><NavLink activeClassName="is-active" to="/settings">Settings</NavLink></li>
              </div> : ''}

              {this.props.data === 'Collaborator' ?
              <div>
                <li className={`listed-property`}><NavLink activeClassName="is-active" to="/dashboard" exact={true}>Listed Properties</NavLink></li>
              </div> : ''}

              {this.props.data === 'Client' || this.props.data === 'Main contact'  ?
              <div>
                <li className={`listed-property`}><NavLink activeClassName="is-active" to="/dashboard" exact={true}>Listed Properties</NavLink></li>
                {this.state.propertyID !== '' ?
                <div>
                  <li className={`property`}><NavLink activeClassName="is-active" to="/property" exact={true}>Your property</NavLink></li>
                  <li className={`documents`}><NavLink activeClassName="is-active" to="/documents">Documents</NavLink></li>
                  <li className={`reports`}><NavLink activeClassName="is-active" to="/reports">Reports & Tasks</NavLink></li>
                  <li className={`agenda`}><NavLink activeClassName="is-active" to="/agenda">Agenda</NavLink></li>
                  <li className={`sensors`}><NavLink activeClassName="is-active" to="/sensors-information">Sensors</NavLink></li>
                </div> : ''}
                <li className={`additional-services`}><NavLink activeClassName="is-active" to="/additional-services">Additional services</NavLink></li>
                <li className={`contact`}><NavLink activeClassName="is-active" to="/contact">Contact us</NavLink></li>
                <li className={`about`}><NavLink activeClassName="is-active" to="/about">About</NavLink></li>
              </div> : ''}

            <li className="logout" onClick={(e) => this.onLogout(e)}><a>Logout</a></li>
          </ul>
          <p className={`copyright text-center blanc ${this.state.btnMenuMobile === true ? 'open' : ''}`}>2020 © Luxus Property Management</p>
        </nav>
      </aside>
    </div>
    );
  }
}

const mapStateToProps = ({auth: {loginStatus}, signup: {user}, popup: {popupData}, property: {propertyID}}) => ({
  propertyID,
  loginStatus,
  user,
  popupData
})

const mapDispatchToProps = (dispatch) => ({
  property: (ID) => dispatch(property({ID})),
  popup: (popupData) => dispatch(popup({popupData})),
  signup: (user) => dispatch(signup({user}))
})

export default connect(mapStateToProps, mapDispatchToProps)(Aside)
